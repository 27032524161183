<template>
    <section class="notifications">
        <b-alert class="notification" variant="success" dismissible
                 v-bind:show="emailVerified && userStatus === 'loggedIn' && user.verified">
            <p class="m-0">{{ $t("message.notifications.verificationSuccess") }}</p>
        </b-alert>

        <b-alert class="notification" variant="warning" dismissible
                 v-bind:show="userStatus === 'loggedIn' && !user.verified">
            <div class="row">
                <div class="col-8 d-flex justify-content-start align-items-center">
                    {{ $t("message.notifications.verificationPending") }}
                </div>
                <div class="col d-flex justify-content-end align-items-center">
                    <button type="button"
                            class="btn btn-light"
                            v-bind:class="{ 'btn-loading': loading }"
                            v-on:click="resendEmailVerification">
                        {{ $t("message.notifications.verificationResend") }}
                    </button>
                </div>
            </div>
        </b-alert>

        <b-toast no-auto-hide
                 solid
                 v-model="updateAvailable">
            <template v-slot:toast-title>
                <strong>
                    {{ $t("message.notifications.updateAvailableTitle") }}
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    {{ $t("message.notifications.updateAvailable") }}
                </div>
                <div class="d-flex align-items-center">
                    <button class="btn btn-sm btn-link"
                            v-on:click="updateApp">
                        {{ $t("message.notifications.updateApp") }}
                    </button>
                </div>
            </div>
        </b-toast>

        <b-toast no-auto-hide
                 solid
                 v-model="isOffline">
            <template v-slot:toast-title>
                <strong>{{ $t("message.notifications.offlineTitle") }}</strong>
            </template>
            {{ $t("message.notifications.offline") }}
        </b-toast>
    </section>
</template>

<script>
import {
    BAlert,
    BToast,
} from "bootstrap-vue";

import resendVerificationEmail from "../../api/user/resendVerificationEmail";

export default {
    name: "Notifications",
    components: {
        BAlert,
        BToast,
    },
    data () {
        return {
            loading: false
        }
    },
    computed: {
        user () {
            return this.$store.state.user.user;
        },

        userStatus () {
            return this.$store.state.user.status;
        },

        emailVerified () {
            return this.$route.query.verified ? this.$route.query.verified : false
        },

        isOffline () {
            return this.$store.state.layout.offline;
        },

        updateAvailable () {
            return this.$store.state.layout.updateAvailable;
        },
    },
    methods: {
        updateApp () {
            if (navigator.serviceWorker) {
                if (this.$store.state.layout.updatedServiceWorker) {
                    let refreshing = false;
                    navigator.serviceWorker
                        .addEventListener(
                            "controllerchange",
                            () => {
                                if (refreshing) {
                                    return;
                                }
                                window.location.reload();
                                refreshing = true;
                            }
                        );

                    this.$store.state.layout.updatedServiceWorker.postMessage({
                        type: "SKIP_WAITING"
                    });
                }
            }
        },

        async resendEmailVerification () {
            this.loading = true;
            try {
                await resendVerificationEmail();
            } catch (e) {
                console.log(e);
                this.loading = false;
                return;
            }
            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.notifications {
    & > .notification {
        border: 0;
        border-radius: 0;
        margin-bottom: 0;
    }
}
</style>
