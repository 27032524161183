<template>
    <aside class="cart-bar position-fixed">
        <div class="text-right pt-3 mr-4 mb-3">
            <button type="button" v-on:click="closeCartBar">
                <font-awesome-icon v-bind:icon="['fal', 'times']"></font-awesome-icon>
            </button>
        </div>

    </aside>
</template>

<script>
    export default {
        name: "CartBar",
        data () {
            return {

            }
        },
        computed: {

        },
        methods: {
            closeCartBar () {
                this.$emit("update:open", !this.open);
                this.open = !this.open;
            },

            checkout () {

            }
        }
    }
</script>

<style lang="scss" scoped>
    .cart-bar {
        top: 0;
        right: -400px;
        bottom: 0;

        width: 375px;
        height: 100%;
        padding-bottom: 150px;
        background-color: rgba(242, 242, 242, 1);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);

        z-index: 11;
        transition: right 600ms ease-in-out, width 800ms ease-in-out;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &.active {
            right: 0;
        }
    }

    @media (max-width: 767px) {
        .cart-bar {
            width: 100%;
            right: -100%;

            box-shadow: none;
        }
    }
</style>
