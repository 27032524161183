<template>
    <section class="view treatment-faq">
        <view-title v-bind:title="$t('message.treatment-faq.title')"></view-title>
        <div class="container py-5"
             v-for="section in faqs"
             v-bind:key="section._id">
            <h2 class="text-center mb-4">
                {{ section.title[$i18n.locale] }}
            </h2>
            <div class="card-faq mb-4"
                 v-for="faq in section.faq"
                 v-bind:key="faq._id">
                <header class="card-header card-faq-header p-0">
                    <button class="btn btn-block btn-faq-toggle text-left d-flex justify-content-between align-items-center"
                            v-on:click="faq.show = !faq.show">
                        <span class="h4 font-weight-light">
                            Q: {{ faq.question[$i18n.locale] }}
                        </span>
                        <span class="h4" v-bind:class="{ 'toggled': faq.show }">
                            <font-awesome-icon v-bind:icon="['fal', 'chevron-down']"></font-awesome-icon>
                        </span>
                    </button>
                </header>
                <b-collapse class="mb-2"
                            v-model="faq.show">
                    <b-card-body>
                        <p class="card-text faq-answer">
                            {{ faq.answer[$i18n.locale] }}
                        </p>
                    </b-card-body>
                </b-collapse>
            </div>
        </div>
    </section>
</template>

<script>
    import {
        BCollapse,
    } from "bootstrap-vue";

    import ViewTitle from "../components/ViewTitle";
    export default {
        name: "TreatmentFAQ",
        components: {
            ViewTitle,
            BCollapse,
        },
        metaInfo: {
            title: "常見問題",
        },
        data () {
            return {

            }
        },
        computed: {
            faqs () {
                return this.$store.state.mall.salon.faqs.map(
                    faq => Object.assign({ show: false }, faq)
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
    .treatment-faq {
        color: rgba(113, 113, 113, 1);

        .card-faq {
            border: none;
            border-bottom: solid 1px rgba(0, 0, 0, 0.125);
            box-shadow: none;
            border-radius: 0;

            .card-faq-header {
                border: none;

                .h4 {
                    transition: transform 350ms ease-in-out;

                    &.toggled {
                        transform: rotate(180deg);
                    }
                }

                & > .btn-faq-toggle {
                    background: white;
                    border: none;

                    & > span {
                        white-space: normal;
                    }
                }
            }

            .faq-answer {
                color: rgba(168, 128, 67, 1);
            }
        }
    }
</style>
