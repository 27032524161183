import localforage from "localforage";
import { apiUrl, apiVersion, } from "../../config";

async function addPaymentSession (session) {
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/wallet/session`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            body: JSON.stringify(session)
        }
    );

    if (response.ok) {
        const paymentSession = await response.json();
        if (paymentSession) {
            return paymentSession;
        }
        throw "WALLET ADD SESSION error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addPaymentSession;
