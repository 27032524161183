import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faPhone as faPhoneSolid,
    faMapMarkerAlt as faMapMarkerAltSolid,
    faTrash as faTrashSolid,
    faPlus as faPlusSolid,
    faPlusCircle as faPlusCircleSolid,
    faMinus as faMinusSolid,
    faTruck as faTruckSolid,
    faCreditCard as faCreditCardSolid,
    faCheckCircle as faCheckCircleSolid,
    faEnvelope as faEnvelopeSolid,
    faDollarSign as faDollarSignSolid,
    faGift as faGiftSolid,
    faTimes as faTimesSolid,
    faCalendarAlt as faCalendarAltSolid,
    faUser as faUserSolid,
    faShoppingCart as faShoppingCartSolid,
    faCalendar as faCalendarSolid,
    faAngleUp as faAngleUpSolid,
    faStar as faStarSolid,
    faCaretRight as faCaretRightSolid,
    faCaretLeft as faCaretLeftSolid,
    faTimesCircle as faTimesCircleSolid,

    faShieldCheck as faShieldCheckSolid,
    faExpandAlt as faExpandAltSolid,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faEyeSlash as faEyeSlashRegular,
    faEye as faEyeRegular,
    faPlayCircle as faPlayCircleRegular,

    faBars as faBarsRegular,
    faGift as faGiftRegular,
    faArrowRight as faArrowRightRegular,
    faArrowLeft as faArrowLeftRegular,
    faChevronDoubleDown as faChevronDoubleDownRegular,
} from "@fortawesome/pro-regular-svg-icons";

import {
    faTimes as faTimesLight,
    faChevronRight as faChevronRightLight,
    faChevronDown as faChevronDownLight,
    faUser as faUserLight,
    faShoppingCart as faShoppingCartLight,
    faClock as faClockLight,
    faSignOut as faSignOutLight
} from "@fortawesome/pro-light-svg-icons";

import {
    faFacebookSquare,
    faInstagram,
    faWeixin,
    faWeibo,
    faGoogle,
    faWhatsapp,
    faTwitter,
    faLine,
    faPaypal,
    faAlipay,
    faTelegramPlane
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faEnvelopeSolid,
    faDollarSignSolid,
    faGiftSolid,
    faCalendarAltSolid,
    faUserSolid,
    faShoppingCartSolid,
    faShieldCheckSolid,
    faExpandAltSolid,
    faCalendarSolid,
    faAngleUpSolid,
    faTimesSolid,
    faPhoneSolid,
    faMapMarkerAltSolid,
    faTrashSolid,
    faPlusSolid,
    faPlusCircleSolid,
    faMinusSolid,
    faTruckSolid,
    faCreditCardSolid,
    faCheckCircleSolid,
    faStarSolid,
    faCaretRightSolid,
    faCaretLeftSolid,
    faTimesCircleSolid,

    faArrowRightRegular,
    faArrowLeftRegular,
    faChevronDoubleDownRegular,
    faBarsRegular,
    faEyeSlashRegular,
    faEyeRegular,
    faPlayCircleRegular,
    faGiftRegular,

    faSignOutLight,
    faTimesLight,
    faChevronRightLight,
    faChevronDownLight,
    faUserLight,
    faShoppingCartLight,
    faClockLight,

    faFacebookSquare,
    faInstagram,
    faWeixin,
    faWeibo,
    faGoogle,
    faWhatsapp,
    faTwitter,
    faLine,
    faPaypal,
    faAlipay,
    faTelegramPlane,
);
