<template>
    <div class="position-sticky bg-light text-dark info-bar"
         v-bind:class="{ 'stuck': infoBarStuck }"
         v-sticky-event="60"
         v-on:stuck="infoBarStuck = true"
         v-on:unstuck="infoBarStuck = false">
        <div class="container">
            <div class="py-2">
                <router-link v-bind:to="{ name: 'product-series', params: { 'id': series._id } }">{{ series.title ? $sanitize(series.title[$i18n.locale]) : "" }}</router-link> | {{ product.title ? $sanitize(product.title[$i18n.locale]) : "" }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PageInfoBar",
        props: {
            "series": {
                "type": Object,
                "required": true,
            },

            "product": {
                "type": Object,
                "required": true,
            }
        },
        data () {
            return {
                infoBarStuck: false,
            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    .info-bar {
        top: 60px;
        z-index: 2;

        .container {
            max-height: 0;
            transition: max-height 1500ms ease-in-out;
            overflow: hidden;
        }

        &.stuck {
            .container {
                max-height: 500px;
            }
        }
    }
</style>
