<template>
    <div class="input-group input-group-sm">
        <div class="input-group-prepend">
            <button class="btn btn-dark rounded-0"
                    type="button"
                    v-on:click="decrement">
                <font-awesome-icon icon="minus"></font-awesome-icon>
            </button>
        </div>
        <input class="form-control text-center"
               type="number"
               min="0"
               step="1"
               disabled
               pattern="\d*"
               v-model="quantity">
        <div class="input-group-append">
            <button class="btn btn-dark rounded-0"
                    type="button"
                    v-on:click="increment">
                <font-awesome-icon icon="plus"></font-awesome-icon>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ItemQuantity",
    props: {
        value: {
            type: Number,
        },
        item: {
            type: Object,
        },
    },
    data() {
        return {

        };
    },
    computed: {
        quantity: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            }
        },
    },
    methods: {
        increment () {
            this.$store.commit(
                "cart/setItemQuantity",
                {
                    "type": "products",
                    "item": this.item,
                    "quantity": 1
                }
            );
        },

        decrement () {
            if (this.quantity > 1) {
                this.$store.commit(
                    "cart/setItemQuantity",
                    {
                        "type": "products",
                        "item": this.item,
                        "quantity": -1
                    }
                );
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.input-group {
    max-width: 120px;
}
</style>
