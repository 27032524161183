export function isCouponEligible (
    cart,
    cartTotal,
    { _id, customerBuys, hasMinAmount, minAmount, hasMinQuantity, minQuantity, isLimitedToOnePerUser },
    orders,
) {
    if (
        hasMinAmount === true &&
        cartTotal < minAmount
    ) {
        return false;
    }

    if (
        hasMinQuantity === true &&
        cart.length < minQuantity
    ) {
        return false;
    }

    if (
        Array.isArray(customerBuys) &&
        customerBuys.length > 0
    ) {
        const intersection = new Set(
            cart
                .map(
                    ({ _id }) => _id
                )
                .filter(
                    x => new Set(customerBuys).has(x)
                )
        );
        if (intersection.size === 0) {
            return false;
        }
    }

    if (isLimitedToOnePerUser === true) {
        return orders
            .filter(
                ({ coupons }) => Array.isArray(coupons) && coupons.length > 0
            )
            .filter(
                ({ coupons }) => coupons.map(({ _id }) => _id).includes(_id)
            )
            .length === 0;
    }

    return true;
}

export default isCouponEligible;
