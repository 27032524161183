<template>
    <div class="position-relative weixin-qrcode">
        <background v-bind:images="bgImages"></background>
        <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-lg btn-outline-light"
                    v-on:click="showWeixinModal = false">
                <font-awesome-icon icon="times"></font-awesome-icon>
            </button>
        </div>
        <div class="container-fluid d-flex flex-column justify-content-center align-items-center pt-4 px-5 pb-5">
            <img class="brand mb-4" src="../../assets/marier_logo_white.svg" alt="marier Skincare 曼肌兒">
            <h5 class="text-center text-white mb-4">曼肌兒抗衰老護膚專家</h5>

            <div class="wechat-qrcode position-relative p-3">
                <qr-code value="https://weixin.qq.com/r/nSpIUA3EDlBxrUkz93_P"
                         size="500"
                         v-bind:options="qrOpts">
                </qr-code>
                <div class="wechat-logo position-absolute d-flex justify-content-center align-items-center p-1">
                    <background v-bind:images="bgImages"></background>
                    <img class="brand mx-1" src="../../assets/marier_logo_white.svg" alt="marier Skincare 曼肌兒">
                </div>
            </div>

            <a class="w-100 wechat-link mb-4"
               href="http://weixin.qq.com/r/nSpIUA3EDlBxrUkz93_P"
               target="_blank"
               rel="noopener nofollow noreferrer">
                <img class="w-100" src="../../assets/wechat_link.svg"
                     alt="marier Skincare曼肌兒 WeChat Link">
            </a>
        </div>
    </div>
</template>

<script>
    import QrCode from "../QrCode";
    import Background from "../Background";
    export default {
        name: "ModalWeixin",
        components: {
            Background,
            QrCode
        },
        data () {
            return {
                qrOpts: {
                    level: "H",
                },
                bgImages: {
                    "image/jp2": "./about_bg.jp2",
                    "image/webp": "./about_bg.webp",
                    "default": "./about_bg.png"
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .weixin-qrcode {
        .brand {
            max-width: 280px;
        }

        .wechat-qrcode {
            width: 100%;
            max-width: 320px;
        }

        .wechat-logo {
            width: 25%;
            height: 25%;
            max-width: 80px;
            max-height: 80px;

            background-color: rgba(255, 255, 255, 1);
            box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.5);
            border-radius: 10px;

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            & > .brand {
                width: 100%;
                height: auto;
            }
        }

        .wechat-link {
            max-width: 320px;
        }

        .btn-outline-light {
            border: none;
        }
    }
</style>
