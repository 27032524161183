<template>
    <button class="btn btn-block btn-dark rounded-0"
            ref="googleSignIn"
            v-bind:class="{ 'btn-loading': userStatus === 'loggingIn' }">
        <font-awesome-icon class="mr-2" v-bind:icon="['fab', 'google']"></font-awesome-icon>GOOGLE
    </button>
</template>

<script>
export default {
    name: "OAuthLogInGoogle",
    computed: {
        userStatus () {
            return this.$store.state.user.status;
        },
    },
    async mounted () {
        // Setup Google Sign-in
        await new Promise(
            (resolve) => {
                gapi.load(
                    "auth2",
                    () => {
                        resolve();
                    }
                );
            }
        );

        let auth2 = gapi.auth2.init({
            client_id: this.$config.GoogleClientId,
            cookiepolicy: "single_host_origin",
            // scope: "additional_scope"
        });

        let googleUser;
        try {
            googleUser = await new Promise(
                (resolve, reject) => {
                    auth2.attachClickHandler(
                        this.$refs.googleSignIn,
                        {},
                        (googleUser) => {
                            resolve(googleUser);
                        },
                        (e) => {
                            reject(JSON.stringify(e, undefined, 2))
                        }
                    );
                }
            )
        } catch (e) {
            console.error(e);
            return;
        }
        console.log(googleUser);

        try {
            await this.$store.dispatch(
                "user/loginByGoogle",
                {
                    token: googleUser.getAuthResponse().id_token,
                    lang: this.$i18n.locale
                }
            );
        } catch (e) {
            console.error(e);
            return;
        }

        this.$router.push({
            "name": "home"
        });
    }
}
</script>

<style lang="scss" scoped>

</style>
