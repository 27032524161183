<template>
    <b-modal centered
             hide-footer
             v-bind:title="$t('message.account.verify-token')"
             v-model="isModalOpen"
             v-on:cancel="closeModal">
        <p>{{ $t("message.account.otp-verify-description") }}</p>
        <div class="form-group">
            <input type="tel"
                   class="form-control text-center"
                   placeholder="XXX XXX"
                   minlength="6"
                   maxlength="6"
                   v-model="token">
        </div>
        <div class="text-center">
            <button class="btn btn-dark verify-otp"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-bind:disabled="token.length !== 6"
                    v-on:click="verifyOTP">
                {{ $t("message.account.verify") }}
            </button>
        </div>
    </b-modal>
</template>

<script>
    import {
        BModal
    } from "bootstrap-vue";

    export default {
        name: "ModalVerifyOTP",
        components: {
            BModal,
        },
        props: {
            "value": {
                type: Boolean,
            },
        },
        data () {
            return {
                isLoading: false,
                token: "",
            };
        },
        computed: {
            isModalOpen: {
                get () {
                    return this.value;
                },
                set (val) {
                    this.$emit("input", val);
                },
            },
        },
        methods: {
            closeModal () {
                this.isModalOpen = false;
            },

            async verifyOTP () {
                this.isLoading = true;
                try {
                    await this.$store.dispatch(
                        "user/verify2FA",
                        {
                            "type": "otp",
                            "token": this.token
                        }
                    );
                } catch (e) {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = false;
                this.isModalOpen = true;
            },
        },
    }
</script>

<style lang="scss" scoped>
    .verify-otp {
        max-width: 280px;
    }
</style>
