<template>
    <div>
        <div class="row no-gutters mb-2">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <table>
                    <tr v-if="isCouponEligible">
                        <td class="text-right">
                            <p class="d-inline-block mb-0 mr-2">
                                {{ $t("message.basket.subTotal") }}
                            </p>
                        </td>
                        <td class="text-left">
                            <h5 class="d-inline-block text-nowrap mb-0">
                                <small>HK$</small>{{ total | currency("", 2) }}
                            </h5>
                        </td>
                    </tr>

                    <tr v-if="isCouponEligible">
                        <td class="text-right">
                            <p class="d-inline-block mb-0 mr-2">
                                {{ $t("message.basket.promotion") }}
                            </p>
                        </td>
                        <td class="text-left">
                            <h5 class="d-inline-block text-danger text-nowrap mb-0">
                                -<small>HK$</small>{{ discountTotal | currency("", 2) }}
                            </h5>
                        </td>
                    </tr>

                    <tr>
                        <td class="text-right">
                            <p class="d-inline-block mb-0 mr-2">
                                {{ $t("message.basket.total") }}
                            </p>
                        </td>
                        <td class="text-left">
                            <h5 class="d-inline-block text-nowrap mb-0">
                                <small>HK$</small>{{ Math.max(total - discountTotal, 0) | currency("", 2) }}
                            </h5>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import isCouponEligible from "../utilities/isCouponEligible";
import calculateDeductibleByCoupon from "../utilities/calculateDeductibleByCoupon";

export default {
    name: "BasketTotal",
    props: {
        isTreatment: {
            type: Boolean,
            default: false,
        },
        cart: {
            type: Array,
        },
        coupon: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        orders () {
            return this.isTreatment === true ?
                this.$store.getters["mall/salonOrders"] :
                this.$store.getters["mall/shopOrders"];
        },
        total () {
            return this.isTreatment === true ?
                this.treatmentCartTotal :
                this.productCartTotal;
        },
        productCartTotal () {
            return this.cart ?
                this.cart.reduce(
                    (acc, product) => acc += product.prices[0].hkd * product.quantity, 0
                ) :
                0;
        },
        treatmentCartTotal () {
            return this.cart ?
                this.cart.reduce(
                    (acc, treatment) => acc += treatment.skus.reduce((acc, sku) => acc += sku.hkd, 0), 0
                ) :
                0;
        },
        isCouponEligible () {
            return this.coupon ?
                isCouponEligible(
                    this.cart,
                    this.total,
                    this.coupon,
                    this.orders
                ) :
                false;
        },
        discountTotal () {
            return this.coupon && this.isCouponEligible === true ?
                calculateDeductibleByCoupon(
                    this.cart,
                    this.total,
                    this.coupon,
                ) :
                0;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
