<template>
    <div class="position-relative w-100 d-flex justify-content-center align-items-center">
        <div class="position-absolute bg-filter"
             v-bind:style="bgFilterStyles">
            <slot name="bg-clip"></slot>
        </div>
        <div class="container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Jumbotron",
        props: {
            bgFilterStyles: {
                type: Object,
            },
        },
    }
</script>

<style lang="scss" scoped>
    .bg-filter {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
        overflow: hidden;
    }
</style>
