<template>
    <div class="list-group">
        <my-basket-header v-bind:is-order="mode === 'order'"
                          v-bind:is-treatment="isTreatment"
                          v-bind:items="items">
        </my-basket-header>

        <my-basket-item v-for="item in items"
                        v-bind:key="item._id"
                        v-bind:item="item"
                        v-bind:is-treatment="isTreatment">
        </my-basket-item>

        <div class="list-group-item list-group-item-action flex-column align-items-start"
             v-if="!isTreatment || discountTotal">
            <div class="d-flex justify-content-between">
                <div class="flex-3">
                    <strong>{{ $t("message.checkout.sub-total") }}</strong>
                </div>
                <div class="flex-1">
                    <h5 class="text-right">
                        <small>HK$</small>{{ 0 | currency("", 2) }}
                    </h5>
                </div>
            </div>
        </div>

        <div class="list-group-item list-group-item-action flex-column align-items-start"
             v-if="parseFloat(discountTotal) > 0">
            <div class="d-flex justify-content-between">
                <div class="flex-3">
                    <strong>{{ $t("message.checkout.promotion") }}</strong>
                </div>
                <div class="flex-1">
                    <h5 class="text-right text-danger text-nowrap">
                        -<small>HK$</small>{{ discountTotal | currency("", 2) }}
                    </h5>
                </div>
            </div>
        </div>

        <div class="list-group-item list-group-item-action flex-column align-items-start"
             v-if="!isTreatment">
            <div class="d-flex justify-content-between">
                <div class="flex-3">
                    <strong>{{ $t("message.checkout.delivery") }}</strong>
                </div>
                <div class="flex-1">
                    <h5 class="text-right">
                        <small>HK$</small>{{ cartDelivery | currency("", 2) }}
                    </h5>
                </div>
            </div>
        </div>

        <my-basket-total v-bind:total="orderTotal"></my-basket-total>
    </div>
</template>

<script>
import isCouponEligible from "../utilities/isCouponEligible";
import calculateDeductibleByCoupon from "../utilities/calculateDeductibleByCoupon";
import getDeliveryClassEligible from "../utilities/getDeliveryClassEligible";

import MyBasketHeader from "./MyBasket/Header";
import MyBasketItem from "./MyBasket/Item";
import MyBasketTotal from "./MyBasket/Total";

export default {
    name: "MyBasket",
    components: {
        MyBasketTotal,
        MyBasketItem,
        MyBasketHeader,
    },
    props: {
        mode: {
            type: String,
        },
        items: {
            type: Array,
        },
        coupons: {
            type: Array,
        },
        discount: {
            type: String,
        },
        options: {
            type: Object,
        }
    },
    data () {
        return {

        }
    },
    computed: {
        isTreatment () {
            return this.$route.params.type === "treatments";
        },
        orders () {
            return this.isTreatment ?
                this.$store.getters["mall/salonOrders"] :
                this.$store.getters["mall/shopOrders"];
        },
        deliveryClasses () {
            return this.$store.state.mall.shop.deliveryClasses;
        },

        cartTotal () {
            return this.items.reduce(
                (acc, product) => acc += product.prices[0].hkd * product.quantity, 0
            );
        },
        coupon () {
            return this.coupons?.[0] ?? null;
        },
        isCouponEligible () {
            return this.coupon ?
                isCouponEligible(
                    this.items,
                    this.cartTotal,
                    this.coupon,
                    this.orders
                ) :
                false;
        },
        discountTotal () {
            if (this.discount) {
                return this.discount;
            }
            if (this.isCouponEligible === false) {
                return 0;
            }
            return this.coupon ?
                calculateDeductibleByCoupon(
                    this.items,
                    this.cartTotal,
                    this.coupon,
                ) :
                0;
        },
        cartDelivery () {
            if (this.options) {
                if (this.options.address && this.options.address.country) {
                    const target = getDeliveryClassEligible(this.deliveryClasses, this.options.address);
                    if (target) {
                        if (target.conditions[0].sign === ">") {
                            if (target.conditions[0].includeEqual) {
                                if (this.cartTotal >= target.conditions[0].value) {
                                    return 0;
                                } else {
                                    return target.methods[0].price.value;
                                }
                            } else {
                                if (this.cartTotal > target.conditions[0].value) {
                                    return 0;
                                } else {
                                    return target.methods[0].price.value;
                                }
                            }
                        }
                    }
                }
            }
            return 0;
        },
        orderTotal () {
            return this.cartTotal - this.discountTotal + this.cartDelivery;
        },
    },
    methods: {

    },
}
</script>

<style scoped>

</style>
