function areGetConditionsFulfilled (
    items,
    customerGets,
    getQuantity,
) {
    // fulfill the get target
    const intersection = items
        .filter(
            ({ _id }) => new Set(customerGets).has(_id)
        );
    if (intersection.length === 0) {
        return false;
    }

    // fulfill get quantity
    const eligibleQuantity = intersection
        .reduce(
            (acc) => {
                acc += 1
                return acc;
            },
            0
        );

    if (eligibleQuantity < getQuantity) {
        return false;
    }

    return true;
}

function areBuyConditionsFulfilled (
    items,
    customerBuys,
    buyQuantity,
    buyAmount,
) {
    // has the buy target
    const intersection = items
        .filter(
            ({ _id }) => new Set(customerBuys).has(_id)
        );

    if (intersection.length === 0) {
        return false;
    }

    if (buyQuantity !== undefined) {
        // fulfill the buy quantity
        const eligibleQuantity = intersection
            .reduce(
                (acc) => {
                    acc += 1
                    return acc;
                },
                0
            );
        if (eligibleQuantity < buyQuantity) {
            return false;
        }
    } else {
        // fulfill the buy amount
        const eligibleSpending = intersection
            .reduce(
                (acc, { prices }) => {
                    acc += prices[0].hkd
                    return acc;
                },
                0
            );
        if (eligibleSpending < buyAmount) {
            return false;
        }
    }
    return true;
}

export function calculateBuyXGetYType (
    cart,
    customerBuys = [],
    buyQuantity,
    buyAmount,
    customerGets = [],
    getQuantity,
    getDiscount,
    maxUsePerOrder
) {
    /* flatten the cart */
    const items = cart.reduce(
        (acc, item) => {
            let items = [];
            for (let i = 0; i < item.quantity; i++) {
                items.push({ ...item, "quantity": undefined })
            }
            return acc.concat(items);
        },
        []
    );

    // Early return conditions
    if (areBuyConditionsFulfilled(items, customerBuys, buyQuantity, buyAmount) === false) {
        return 0;
    }

    if (areGetConditionsFulfilled(items, customerGets, getQuantity) === false) {
        return 0;
    }
    /************************************/

    const irrelevantItems = items
        .slice(0)
        .filter(
            ({ _id }) => !(new Set(customerBuys).has(_id)) && !(new Set(customerGets).has(_id))
        );
    let relevantItems =
        items
            .slice(0)
            .filter(
                ({ _id }) => new Set(customerBuys).has(_id) || new Set(customerGets).has(_id)
            );

    // Group customer buys into groups that meet the buy and get condition first
    let groups;
    if (buyQuantity !== undefined) {
        // byQuantity
        // try to fulfill get condition first
        // fill groups by get and get quantity
        let remainingItems = relevantItems.slice(0);
        let groupIndex = 0;
        let groups = [{
            buy: [],
            get: [],
        }];
        for (let i = 0; i < relevantItems.length; i++) {
            if (new Set(customerGets).has(relevantItems[i]._id)) {
                if (groups[groupIndex].get.length < getQuantity) {
                    groups[groupIndex].get.push(Object.assign({}, relevantItems[i]));
                } else {
                    groups.push({
                        buy: [],
                        get: [],
                    });
                    groupIndex ++;
                    groups[groupIndex].get.push(Object.assign({}, relevantItems[i]));
                }
                remainingItems.splice(
                    remainingItems.findIndex(
                        ({ _id }) => _id === relevantItems[i]._id
                    ),
                    1
                );
            }
        }

        relevantItems = remainingItems.slice(0);

        // try to fulfill buy condition
        // fill groups by buy and buy quantity
        for (let i = 0; i < relevantItems.length; i++) {
            if (new Set(customerBuys).has(relevantItems[i]._id)) {
                if (groups[groupIndex].buy.length < buyQuantity) {
                    groups[groupIndex].buy.push(Object.assign({}, relevantItems[i]));
                } else {
                    groupIndex ++;
                    groups[groupIndex].buy.push(Object.assign({}, relevantItems[i]));
                }
                remainingItems.splice(
                    remainingItems.findIndex(
                        ({ _id }) => _id === relevantItems[i]._id
                    ),
                    1
                );
            }
        }

        // move *BUY items first* and *GET items next* from one group to another
        // to try to fulfill *GET conditions first* and *BUY conditions* next
        // DONOR BUY to TARGET GET
        // DONOR GET to TARGET BUY
        /*{
            buy: [], get: [],
        }*/

        for (let i = 0; i < groups.length; i++) {
            const target = groups[i];
            for (let j = (i + 1); j < groups.length; j++) {
                const donor = groups[j];
                // DONOR BUY TO RECEIVER GET
                while (
                    donor.buy.length > 0 &&
                    areGetConditionsFulfilled(target.get, customerGets, getQuantity) === false
                ) {
                    target.get.push(
                        Object.assign({}, donor.buy[0])
                    );
                    donor.buy.splice(0, 1);
                }
                // DONOR GET TO RECEIVER BUY
                while (
                    donor.get.length > 0 &&
                    areBuyConditionsFulfilled(target.buy, customerBuys, buyQuantity, buyAmount) === false
                ) {
                    target.buy.push(
                        Object.assign({}, donor.get[0])
                    );
                    donor.get.splice(0, 1);
                }
                // DONOR GET TO RECEIVER GET
                while (
                    donor.get.length > 0 &&
                    areGetConditionsFulfilled(target.get, customerGets, getQuantity) === false
                    ) {
                    target.get.push(
                        Object.assign({}, donor.get[0])
                    );
                    donor.get.splice(0, 1);
                }
                // DONOR BUY TO RECEIVER BUY
                while (
                    donor.buy.length > 0 &&
                    areBuyConditionsFulfilled(target.buy, customerBuys, buyQuantity, buyAmount) === false
                    ) {
                    target.buy.push(
                        Object.assign({}, donor.buy[0])
                    );
                    donor.buy.splice(0, 1);
                }
            }
        }

        groups = groups
            .filter(
                ({ buy, get }) => areGetConditionsFulfilled(get, customerGets, getQuantity) &&
                    areBuyConditionsFulfilled(buy, customerBuys, buyQuantity, buyAmount)
            );

        if (maxUsePerOrder) {
            groups = groups.splice(0, maxUsePerOrder);
        }

        return groups.reduce(
            (acc, { get }) => {
                acc += get.reduce(
                    (acc, { prices }) => {
                        acc += prices[0].hkd
                        return acc;
                    },
                    0
                );
                return acc;
            },
            0
        );
    } else {
        // byAmount
        groups = cart.reduce(
            (acc, item) => {

            },
            []
        );
    }

    return 0;
}

export default calculateBuyXGetYType;
