export function calculateFixedAmountType (
    cart,
    customerBuys = [],
    discountAmount,
) {
    if (
        Array.isArray(customerBuys) &&
        customerBuys.length > 0
    ) {
        const intersection = new Set(
            cart
                .filter(
                    x => new Set(customerBuys).has(x._id)
                )
        );
        if (intersection.size === 0) {
            return 0;
        }
        const eligibleSpending = [...intersection]
            .reduce(
                (acc, { prices, quantity }) => {
                    acc += prices[0].hkd * quantity
                    return acc;
                },
                0
            );
        return Math.min(eligibleSpending, discountAmount);
    }
    return discountAmount;
}

export default calculateFixedAmountType;
