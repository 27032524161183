<template>
    <main class="campaign-wrapper w-100">
        <router-view></router-view>
    </main>
</template>

<script>
export default {
    name: "Campaign"
}
</script>

<style lang="scss">
.campaign-wrapper {
    section {
        &.home {
            min-height: 100vh;
        }

        &.methods {
            .cards {
                .card {
                    border: none;
                    border-radius: 0;

                    .card-body {
                        .card-title {
                            min-height: 100px;
                        }

                        .card-text {
                            .row {
                                border-bottom: solid 1px rgba(201, 201, 201, 1);

                                .header {
                                    color: rgba(22, 73, 154, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
