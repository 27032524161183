import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./stores/index";

Vue.config.productionTip = false;

//import "u2f-api-polyfill";

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

import "intersection-observer";

import Autocomplete from "v-autocomplete";
import "v-autocomplete/dist/v-autocomplete.css";
Vue.use(Autocomplete);

/*import VueScrollmagic from "vue-scrollmagic";
Vue.use(VueScrollmagic, {
    globalSceneOptions: {},
    //loglevel: 3,
    refreshInterval: 100
});*/

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    Vue,
    dsn: "https://b06901e6eeab4d2fa94698ce320570e2@o474560.ingest.sentry.io/5626061",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import vue2GoogleMap from "./init/vue2-google-maps";
vue2GoogleMap(Vue);

import VueI18n from "./init/vue-i18n";
const i18n = VueI18n(Vue);

import "./init/font-awesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import lineClamp from "vue-line-clamp";
Vue.use(lineClamp, {});

import VueChartist from "vue-chartist";
Vue.use(VueChartist);

//import VueYoutube from "vue-youtube";
//Vue.use(VueYoutube);

import VueProgressiveImage from "vue-progressive-image";
Vue.use(VueProgressiveImage);

import {
    capitalize,
    currency
} from "./plugins/filters";
Vue.use(capitalize);
Vue.use(currency);

import vueDateFnsFormatter from "./plugins/date-fns-formatter/index";
Vue.use(vueDateFnsFormatter);

import {
    FacebookAppId
} from "./config";

import VueMultianalytics from "vue-multianalytics";
Vue.use(
    VueMultianalytics,
    {
        "modules": {
            "ga": {
                appName: "marier Skincare",
                appVersion: "1.0",
                trackingId: "UA-62227809-5",
                ecommerce: true,
                debug: true,
            },
            "facebook": {
                token: FacebookAppId,
                debug: true,
            }
        },
        "routing": {
            vueRouter: router,
            preferredProperty: "path",
            ignoredViews: [],
            ignoredModules: []
        }
    }
);

import VueGtm from "@gtm-support/vue2-gtm";
Vue.use(
    VueGtm,
    {
        "id": "GTM-57MCJX4",
        "enabled": true,
        "debug": true,
        "vueRouter": router,
    }
);

import {
    baseUrl,
    apiUrl,
    apiVersion,
    PaypalKeys,
    GoogleClientId,
    SupportedSNS,
} from "./config";
import VueConfigurator from "./plugins/configurator/index";
Vue.use(
    VueConfigurator,
    {
        baseUrl,
        apiUrl,
        apiVersion,
        PaypalKeys,
        GoogleClientId,
        SupportedSNS,
    }
);

//import VueNProgress from "./plugins/nprogress/index";
import "nprogress/nprogress.css";
//Vue.use(VueNProgress);

import VueDetectBrowser from "./plugins/detect-browser/index";
Vue.use(VueDetectBrowser);

import VueSanitizeHtml from "./plugins/sanitize-html/index";
Vue.use(VueSanitizeHtml);

import VueStickyEvent from "./plugins/directives/sticky-event";
Vue.use(VueStickyEvent);

import {
    stripeKey
} from "./config";
import StripeInit from "./plugins/stripe/index";
Vue.use(StripeInit, stripeKey);

import Meta from "vue-meta";
Vue.use(Meta);

import "aos/dist/aos.css";

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");

import "./registerServiceWorker"
