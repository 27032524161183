export default {
    "zh-hk": {
        message: {
            "notifications": {
                "verificationSuccess": "您的電郵已經通過驗證。您現在可以購物及預約療程。",
                "verificationPending": "您的電郵仍在等候驗證。請在收件箱中查看驗證郵件。如果您沒有收到電郵，請檢查您的垃圾郵件文件夾或要求重新發送。",
                "verificationResend": "重新發送驗證郵件",
                "offlineTitle": "閣下現在目前處於離線狀態",
                "offline": "本網站上的信息可能已過期。閣下於離線期間提交的訂單和預訂將在重新連線時處理。",
                "updateAvailableTitle": "有可用更新",
                "updateAvailable": "本應用程式有新版本可用。點擊更新👉",
                "updateApp": "更新",
            },

            "back-to-top-btn": {
                "title": "返回頁首"
            },

            "navbar": {
                "menu": "選單",
                "basket": "購物車",
                "login-register": "登入/註冊",
                "my-account": "我的帳戶",
                "sign-out": "登出",
            },

            "login": {
                "title": "註冊/登入",
                "login": "登入",
                "login-sns": "使用社交網絡帳戶登錄：",
                "login-sns-or": "或",
                "login-sns-notice-1": "使用社交網絡帳戶登錄代表閣下已同意我們的",
                "login-sns-notice-2": "私隱政策",
                "login-sns-notice-3": "使用條款",
                "login-sns-notice-and": "及",
                "register-line": "按此進入新會員註冊頁面：",
                "register": "註冊",
                "email": "電郵地址",
                "phone": "手機號碼",
                "region-code": "電話區號",
                "password": "密碼",
                "forget-password": "忘記密碼？",
                "forget-password-line": "請輸入你註冊時使用的電郵地址。我們會向該電郵地址發送一封包含重置密碼指示的電子郵件。",
                "invalid-email": "請輸入有效的電郵地址。",
                "invalid-phone": "請輸入有效的電話號碼。",
                "invalid-password": "請輸入密碼。",
                "incorrect-login": "登入資料不正確。請重新輸入。",
            },

            "registration": {
                "title": "新會員註冊",
                "form-title": "稱謂",
                "title-miss": "小姐",
                "title-ms": "女士",
                "title-mr": "先生",
                "surname": "姓氏",
                "name": "名字",
                "email": "電郵地址",
                "country-code": "電話區號",
                "phone": "電話號碼",
                "password": "密碼",
                "show-password": "顯示密碼",
                "hide-password": "隱藏密碼",
                "receive-promotion": "你希望收到marier Skincare曼肌兒的資訊及優惠嗎？",
                "receive-promotion-yes": "是",
                "receive-promotion-no": "否",
                "how-marier-found": "你是如何找到marier Skincare曼肌兒？",
                "accept-tc-1": "我已閱讀並同意",
                "accept-tc-2": "私穏政策",
                "accept-tc-3": "使用條款",
                "accept-tc-and": "及",
                "accept-tc-full-stop": "。",
                "register": "註冊",
            },

            "forget-password": {
                "title": "重設你的密碼",
                "confirm": "確認"
            },

            "account": {
                "title": "我的帳戶",
                "dashboard": "帳戶概覽",
                "orders": "產品訂單",
                "bookings": "療程預訂",

                "account": "帳戶",
                "security": "安全",
                "payment-shipping": "付款和運送",
                "message-options": "來自 marier 的訊息",

                "name": "姓名",
                "phone": "電話號碼",
                "email": "電郵地址",
                "language": "語言",

                "change-email-notice": "帳戶驗證電郵將會發送到新輸入的電郵地址。您必須再次驗證自己的帳戶。",
                "change-phone-notice": "我們將會再次驗證您的電話號碼。",

                "password": "密碼",
                "change-password": "更改密碼",
                "current-password": "當前密碼",
                "new-password": "新密碼",

                "twoFactorAuth": "雙重認證",
                "enable-two-factor-auth": "啟用雙重認證",
                "disable-two-factor-auth": "停用雙重認證",

                "one-time-password": "單次密碼",
                "otp-description": "支援單次密碼應用程式，例如Google身份驗證器，Microsoft身份驗證器及Authy。",
                "otp-verify-description": "請輸入由單次密碼應用程序生成的6位數安全碼以進行驗證：",
                "verify-token": "驗證安全碼",
                "scan-enter-otp": "使用您選用的單次密碼應用程式掃描上述QR碼或手動輸入：",
                "disable-otp": "停用單次密碼",

                "hw-security-key": "硬體安全鑰匙",
                "hw-security-key-description": "支援符合FIDO U2F標準的硬體安全鑰匙。",

                "no-data": "沒有提供",
                "enable": "啟用",
                "enabled": "已啟用",
                "disable": "停用",
                "edit": "編輯",
                "done": "完成",
                "verify": "驗證",

                "pay-order": "支付訂單",
                "update-order": "更新訂單",
                "cancel-order": "取消訂單",
            },

            "basket": {
                "products": {
                    "title": "產品",
                    "empty": {
                        "headline": "Your basket is empty",
                        "subheadline": "Make it happy with some of our products!"
                    }
                },
                "treatments": {
                    "title": "療程",
                    "empty": {
                        "headline": "Your basket is empty",
                        "subheadline": "Make it happy with some of our treatments!"
                    },
                    "options": "療程選項：",
                    "date": "療情日期：",
                    "branch": "預約分店："
                },
                "quantity": "數量",
                "unit-price": "單價：",
                "delivery": "運費：",
                "sub-total": "金額",
                "subTotal": "小計：",
                "total": "總計：",
                "delete": "刪除",
                "login-verification-required": "請先登入或註冊",
                "promotion": "折扣優惠：",
                "have-a-coupon-code": "有優惠卷嗎？",
                "coupon-code": "優惠卷號碼",
                "coupon-code-applied": "已應用優惠券",
                "redeem": "兌換",
            },

            "checkout": {
                "title": "結帳",
                "delivery": "運費",
                "promotion": "折扣優惠",
                "sub-total": "金額",
                "total": "總計",
                "my-basket": "我的購物籃",
                "my-order": "我的訂單",
                "products": "種產品",
                "treatments": "療程",
                "items": "項",
                "contact": "聯絡方法",
                "contact-email-notice": "訂單確認通知將發送到此電郵地址。",
                "contact-phone-notice": "我們可能會透過這個電話號碼與閣下聯繫。",
                "delivery-address": "送貨地址",
                "delivery-method": "送貨服務",
                "delivery-standard": "標準郵遞",
                "payment-method": "付款方法",
                "first-name": "名字",
                "last-name": "姓氏",
                "email": "電郵地址",
                "phone": "電話號碼",
                "optional": "可選",
                "country": "國家 / 地區",
                "postcode": "郵政編碼",
                "next": "下一步",
                "confirm": "確認",
                "confirm-order": "確認訂單",
                "edit": "編輯",
                "add": "新增",
                "add-address": "新增地址",
                "address": {
                    "title": "地址",
                    "select": "選擇此地址",
                    "selected": "已選擇此地址",
                    "delete": "刪除",
                },
                "add-another-card": "使用另一張卡",
                "accept-tc-1": "我接受",
                "accept-tc-2": "條款及細則",
                "accept-tc-3": "。",
                "thank-you": "多謝惠顧！",
                "pay-on-site-notice": "如閣下欲以現金付款或希望申請免息分期付款，請選擇「現場付款」。網上付款只接受信用卡、PayPal、支付寶及微信支付。",
                "payment-methods": {
                    "pay-on-site": "現場付款",
                    "cards": "付款卡",
                    "paypal": "PayPal",
                    "alipay": "支付寶",
                    "wechat-pay": "微信支付",
                },
                "card": {
                    "select": "選擇此卡",
                    "selected": "已選擇此卡",
                    "delete": "刪除",
                    "last-4-digit": "最後4位數字：",
                    "valid-till": "有效期至：",
                    "name": "持卡人姓名",
                },
                "add-card": {
                    "title": "添加付款卡",
                    "card-name": "持卡人姓名",
                    "card-name-hint": "卡上顯示的全名",
                    "card-number": "卡號",
                    "card-expiry": "卡到期日",
                    "card-cvc": "CVC/CVV",
                    "buttons": {
                        "add": "添加",
                        "cancel": "取消",
                    },
                },
                "payment-request-api-or-cards": "或者你可以在下面選擇或添加付款卡",
                "payment-in-progress": "付款進行中...",
                "cancel-payment": "取消付款",
                "payment-failed": "付款失敗。",
                "retry-payment": "再試一次？",
                "confirm-email-sent": "訂單確認已發送到您的電子郵件地址：",
            },

            "home": {
                "introduction": "簡介",
                "tag-line-1": "停下時光 靜止衰老",
                "tag-line-2": "以科研逆轉肌齡",
                "intro-1": "marier Skincare 曼肌兒 深信幹細胞對肌膚的重要性，與韓國頂尖科研團隊合作，於世界各地搜羅最優質原材料，針對肌膚衰老問題。",
                "intro-2": "以高科技技術研製高效抗衰老產品，成功研發出針對幹細胞生長，有高濃度親和性的人體骨髓幹細胞培養液 Stem Cell Conditioned Media (SCCM)。",
                "intro-3": "能強效抗衰老，高速修復受損細胞，為肌膚補充水分營養， 是世界No.1幹細胞權威專家。",
                "featured-products": "皇牌產品",
                "featured-treatments": "皇牌療程",
                "know-more": "查詢詳情",
            },

            "treatments": {
                "title": "療程一覽",
                "options": "療程選項",
                "select-sku": "請選擇所需療程：",
                "select-date": "選擇日期",
                "datepicker-placeholder": "點擊以選擇日期",
                "select-time": "選擇時間",
                "select-branch": "選擇分店",
                "select-branch-region": "地區",
                "duration": "所需時間",
                "openHours": "營業時間：",
                "hotline": "查詢電話：",
                "price": "金額：",
                "book-now": "加入購物車",
            },

            "treatment-faq": {
                "title": "常見問題",
            },

            "products": {
                "title": "產品一覽",
                "add-to-cart": "加入購物車",
                "added-to-cart": "已加進購物車",
                "share-to": "分享至：",
                "introduction": "產品介紹",
                "usage": "使用方法",
                "payment-methods-terms": "付款方法",
                "online-shopping-terms": "網上購物條款",
                "terms": "條款及細則",
                "delivery-methods-terms": "訂購條款及送貨服務",
            },

            "promotions": {
                "book-now": "立即預約",
            },

            "blog": {
                "title": "網誌",
            },

            "news": {
                "title": "最新消息",
            },

            "article": {
                "read-more": "閱讀全文",
            },

            "about": {
                "title": "關於marier",
                "tag-line-lead": "世界No.1幹細胞權威專家marier Skincare曼肌兒宗旨：",
                "tag-line": "「探索和融合世界一切美好事物，以溫和天然成分及突破性的技術，激活肌膚再生。」",
                "paragraph-1": "marier Skincare曼肌兒於2012年創辦，是世界No.1幹細胞權威專家，於世界各地搜羅最優質原材料，針對肌膚衰老問題，以高科技技術研製不同革新並優質的高效抗衰老產品，迎合不同亞洲人士，務求能讓更多人認識marier的產品及美容服務。",
                "paragraph-2": "marier Skincare曼肌兒深信幹細胞對肌膚的重要，因此與韓國頂尖科研團隊合作，成功研發出針對幹細胞生長，用於護膚品的高濃度親和性的人體骨髓幹細胞培養液 Stem Cell Conditioned Media (SCCM)，並取得國際FDA專利，能高度及迅速修復受損細胞，為肌膚補充水分營養。",
                "pharmicell-title": "頂尖韓國科研合作團隊",
                "paragraph-3": "Pharmicell 是位於韓國世界最早開發幹細胞治療劑的科研團隊，是全球生物醫藥品的引領者。其細胞療法實驗室成立於2002年10月，主要致力於幹細胞藥物的商業化研究。業務領域包括幹細胞治療產品，生化學，醫藥品委託代工業務，幹細胞銀行及幹細胞護膚品。",
                "paragraph-4": "幹細胞護膚品是通過Pharmicell尖端技術誕生的幹細胞培養液。蘊含人體幹細胞培養液提取物，能有效肌膚歲月逆轉。SCCM能超越時間，重現美麗的護膚品，其幹細胞培養液內含皮膚生長因子和各種活性成分，使皮膚健康亮澤，展現最美麗的肌膚。",
                "paragraph-5": "根據光照性皮膚疾病領域世界最高權威期刊，針對幹細胞培養液具有皮膚保濕與皺紋改善效果的發表論文指出SCCM能有效改善皺紋，增加膠原蛋白及減少水分流失。",
                "paragraph-6": "我們累積經驗，以專業角度及臨床實證了解亞洲女性的肌膚需求。以革新科技鼓舞我們不斷追求更高效卓越的產品。公司堅持與頂尖團隊合作，研發更適合現今女性的產品。",
                "paragraph-7": "我們期望與客人找到愛與信任。我們專業的美容顧問以摯誠的態度、從你們的需要出發，為每一位帶來貼心個人化護理療程。",
            },

            "locations": {
                "title": "分店位置"
            },

            "privacy": {
                "title": "私隱政策"
            },

            "terms": {
                "title": "條款及細則"
            },

            "delivery-methods": {
                "title": "訂購條款及送貨服務",
                "delivery-cat-1": "marierskincare.com提供全球送貨服務。收貨國家/地區為",
                "delivery-cat-2": "類。",
                "delivery-method": "送貨方式",
                "purchase-condition-larger-equal": "購物少於港幣",
                "purchase-condition-larger-1": "購物港幣",
                "purchase-condition-larger-2": "或以下",
                "purchase-condition-free-larger-equal-1": "購物港幣",
                "purchase-condition-free-larger-equal-2": "或以上",
                "purchase-condition-free-larger": "購物高於港幣",
                "delivery-time": "運送時間（工作日）",
                "free": "免費",
            },

            "payment-methods": {
                "title": "付款方法",
            },

            "online-shopping-terms": {
                "title": "線上購物條款"
            },

            "contact-us": {
                "title": "聯絡我們",
                "address": "香港尖沙咀彌敦道132號Mira Place A座6樓602室",
                "form-title": "立即查詢",
                "form-name-title": "稱謂",
                "form-name": "姓名",
                "form-gender": "性別",
                "form-gender-male": "男",
                "form-gender-female": "女",
                "form-email": "電郵地址",
                "form-country-code": "電話區號",
                "form-phone": "聯絡電話",
                "form-content": "內容",
                "form-terms-checkbox": "本人同意所提供的資料可按照本人已閱讀、理解及同意之法律聲明及私隱政策而作出持有及使用。",
                "form-send": "發送",
                "form-complete-title": "感謝您聯絡我們！",
                "form-complete": "我們已經收到您的聯絡，我們會盡快回覆您。",
                "form-error-title": "我們遇到了一些問題",
                "form-error": "對不起，請稍後再試。",
            },
        }
    }
}
