<template>
    <section class="progress-bar-custom d-flex flex-row justify-content-between align-items-center mt-3 mb-4">
        <template v-for="(step, index) in steps">
            <router-link class="step flex-1 text-center mr-1"
                         v-if="index < activeStep"
                         v-bind:key="index"
                         v-bind:class="{ 'active': index === activeStep, 'completed': index < activeStep }"
                         v-bind:to="step.to">
                <div class="step-icon d-inline-flex justify-content-center align-items-center">
                    <font-awesome-icon v-bind:icon="step.icon"></font-awesome-icon>
                </div>
                <span>
                    {{ step.title[$i18n.locale] }}
                </span>
            </router-link>

            <div class="step flex-1 text-center mr-1"
                 v-if="index >= activeStep"
                 v-bind:key="index"
                 v-bind:class="{ 'active': index === activeStep }">
                <div class="step-icon d-inline-flex justify-content-center align-items-center">
                    <font-awesome-icon v-bind:icon="step.icon"></font-awesome-icon>
                </div>
                <span>
                    {{ step.title[$i18n.locale] }}
                </span>
            </div>

            <hr class="flex-2 mr-1"
                v-bind:key="index"
                v-if="index < steps.length - 1">
        </template>
    </section>
</template>

<script>
    export default {
        name: "ProgressBar",
        props: {
            steps: {
                type: Array,
                required: true
            },
            activeStep: {
                type: Number,
                default: 0
            }
        },
        data () {
            return {

            }
        },
        computed: {

        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    .progress-bar-custom {
        height: 32px;
        color: rgba(113, 113, 113, 1);

        hr {
            border: solid;
            border-width: thin 0 0 0;
            border-color: rgba(113, 113, 113, 1);
            height: 0;
            width: 100%;
            margin: 0;
        }

        .step {
            color: rgba(113, 113, 113, 1);
            border: none;
            outline: none;
            white-space: nowrap;

            & > .step-icon {
                border-radius: 50%;
                padding: 8px;
                margin-right: 8px;
                color: rgba(255, 255, 255, 1);
                background: rgba(0, 0, 0, 0.38);

                height: 36px;
                width: 36px;
            }

            &.active {
                font-weight: bold;

                & > .step-icon {
                    background: rgba(113, 113, 113, 1);
                }
            }

            &.completed {
                & > .step-icon {
                    background: rgba(33, 136, 56, 1);
                }
            }
        }
    }
</style>
