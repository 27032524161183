<template>
    <section class="view checkout">
        <view-title reveal-animation="fade-down"
                    v-if="$route.name !== 'checkout-completion'"
                    v-bind:title="$t('message.checkout.title')">
        </view-title>
        <view-title reveal-animation="fade-down"
                    v-else
                    v-bind:title="$t('message.checkout.thank-you')">
        </view-title>

        <div class="container py-5">
            <div class="row">
                <div class="main-view col-lg-8 order-1 order-lg-0 mt-5 mt-md-0"
                     v-bind:class="{ 'col-lg-12': hideBasket }">
                    <progress-bar v-if="$route.name !== 'checkout-completion'"
                                  v-bind:steps="steps[type]"
                                  v-bind:active-step="activeStep">
                    </progress-bar>
                    <router-view v-bind:type="type"></router-view>
                </div>

                <div class="col-lg-4 order-0 order-lg-1"
                     v-bind:class="{ 'd-none': hideBasket }">
                    <my-basket mode="cart"
                               v-if="cart.length > 0 && !$route.params.orderId"
                               v-bind:items="cart"
                               v-bind:coupons="coupons"
                               v-bind:options="options.delivery">
                    </my-basket>
                    <my-basket mode="order"
                               v-if="$route.params.orderId"
                               v-bind:items="order.items"
                               v-bind:coupons="coupons"
                               v-bind:discount="order.discountTotal"
                               v-bind:options="order.delivery">
                    </my-basket>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ViewTitle from "../components/ViewTitle";
import ProgressBar from "../components/Checkout/ProgressBar";
import MyBasket from "../components/Checkout/MyBasket";

export default {
    name: "Checkout",
    components: {
        MyBasket,
        ProgressBar,
        ViewTitle
    },
    metaInfo: {
        title: "結帳",
    },
    props: {
        type: {
            type: String,
        },
    },
    data () {
        return {
            steps: {
                products: [{
                    "icon": ["fas", "truck"],
                    "to": {
                        name: "checkout-delivery",
                    },
                    "title": {
                        "en": "Delivery",
                        "zh-hk": "送貨服務",
                        "zh-cn": "送货服务",
                    },
                }, {
                    "icon": ["fas", "gift"],
                    "to": {
                        name: "checkout-order-details",
                    },
                    "title": {
                        "en": "Order Particulars",
                        "zh-hk": "訂單內容",
                        "zh-cn": "订单内容",
                    },
                }, {
                    "icon": ["fas", "credit-card"],
                    "to": {
                        name: "checkout-payment",
                        params: {
                            "orderId": this.$route.params.orderId
                        },
                    },
                    "title": {
                        "en": "Payment",
                        "zh-hk": "付款",
                        "zh-cn": "付款",
                    },
                }] ,
                treatments: [{
                    "icon": ["fas", "phone"],
                    "to": {
                        name: "checkout-delivery",
                    },
                    "title": {
                        "en": "Contact",
                        "zh-hk": "聯絡方法",
                        "zh-cn": "联络方法",
                    },
                }, {
                    "icon": ["fas", "gift"],
                    "to": {
                        name: "checkout-order-details",
                    },
                    "title": {
                        "en": "Order Particulars",
                        "zh-hk": "訂單內容",
                        "zh-cn": "订单内容",
                    },
                }, {
                    "icon": ["fas", "credit-card"],
                    "to": {
                        name: "checkout-payment",
                        params: {
                            "orderId": this.$route.params.orderId
                        },
                    },
                    "title": {
                        "en": "Payment",
                        "zh-hk": "付款",
                        "zh-cn": "付款",
                    },
                }]
            },

            deliveryOptions: null,
            paymentOptions: null,

            hideBasket: false,
        }
    },
    computed: {
        cart () {
            return this.type === "products" ?
                this.$store.state.cart.cart.products :
                this.$store.state.cart.cart.treatments;
        },
        coupons () {
            return this.type === "products" ?
                this.$store.state.cart.coupon.products :
                this.$store.state.cart.coupon.treatments;
        },
        options () {
            return this.$store.state.wallet.paymentSession;
        },
        activeStep () {
            switch (this.$route.name) {
                case "checkout-delivery": {
                    return 0;
                }
                case "checkout-order-details": {
                    return 1;
                }
                case "checkout-payment": {
                    return 2;
                }
                default: {
                    return 0;
                }
            }
        },
        orders () {
            return this.$route.params.type === "treatments" ?
                this.$store.getters["mall/salonOrders"] :
                this.$store.getters["mall/shopOrders"];
        },
        order () {
            if (this.$route.params.orderId) {
                const target = this.orders
                    .find(
                        order => order._id === this.$route.params.orderId
                    );

                if (target) {
                    return target;
                } else {
                    return {
                        items: [],
                        delivery: {},
                        payment: {}
                    };
                }
            }
            return null;
        },
    },
    methods: {

    },
    mounted () {
        if (["checkout-order-details", "checkout-completion"].includes(this.$route.name)) {
            this.hideBasket = true;
        }
    },
    watch: {
        "$route" (newVal) {
            this.hideBasket = ["checkout-order-details", "checkout-completion"].includes(newVal.name);
        }
    }
}
</script>

<style lang="scss" scoped>
.checkout {
    .main-view {
        transition: flex 1000ms ease;
    }
}
</style>
