<template>
    <div class="banner position-relative d-flex">
        <background-filter v-if="!treatment.videoCover"
                           v-bind:color="'transparent'"
                           v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/salon/treatment/image/${ treatment.cover }`">
        </background-filter>
        <background-filter v-else
                           v-bind:is-video="treatment.videoCover"
                           v-bind:provider="treatment.videoCover.provider"
                           v-bind:src="treatment.videoCover.src"
                           v-bind:poster="`${ $config.apiUrl }/${ $config.apiVersion }/salon/treatment/image/${ treatment.cover }`">
        </background-filter>
        <div class="container pt-5">
            <div class="row h-100">
                <div class="d-flex flex-column justify-content-center"
                     v-bind:class="{ 'col-md-7': treatment.images.length > 0, 'col-md-12': treatment.images.length === 0 }"
                     data-aos="fade-in"
                     data-aos-duration="1000"
                     data-aos-delay="400">
                    <!-- <h1 class="text-center text-md-left"
                        v-html="product.title[$i18n.locale]"></h1>
                    <p class="text-center text-md-left"
                       v-html="product.subtitle[$i18n.locale]"></p>-->
                    <h1 class="text-center text-md-left mb-4 font-weight-light"
                        v-bind:style="{ 'color': treatment.titleColor ? `rgba(${ treatment.titleColor.r }, ${ treatment.titleColor.g }, ${ treatment.titleColor.b }, ${ treatment.titleColor.a }) !important` : '' }"
                        v-html="treatment.title[$i18n.locale]">
                    </h1>

                    <p class="text-center text-md-left mb-4 lead"
                       v-bind:style="{ 'color': treatment.titleColor ? `rgba(${ treatment.titleColor.r }, ${ treatment.titleColor.g }, ${ treatment.titleColor.b }, ${ treatment.titleColor.a }) !important` : '' }"
                       v-html="treatment.subtitle[$i18n.locale]">
                    </p>

                    <div class="d-block d-sm-none text-center">
                        {{ $t("message.products.share-to") }}
                    </div>

                    <div class="d-flex align-items-baseline justify-content-center justify-content-md-start">
                        <div class="d-none d-sm-inline mr-sm-2"
                             v-bind:style="{ 'color': treatment.shareColor ? `rgba(${ treatment.shareColor.r }, ${ treatment.shareColor.g }, ${ treatment.shareColor.b }, ${ treatment.shareColor.a }) !important` : '' }">
                            {{ $t("message.products.share-to") }}
                        </div>

                        <share-to-s-n-s v-bind:networks="$config.SupportedSNS"
                                        v-bind:style="{ 'color': treatment.shareColor ? `rgba(${ treatment.shareColor.r }, ${ treatment.shareColor.g }, ${ treatment.shareColor.b }, ${ treatment.shareColor.a }) !important` : '' }"
                                        v-bind:url="`${ $config.baseUrl }${ $route.path }`"
                                        v-bind:title="`${ treatment.title[$i18n.locale].replace(/(<([^>]+)>)/ig, '') }`"
                                        v-bind:description="treatment.subtitle[$i18n.locale].replace(/(<([^>]+)>)/ig, '')"
                                        v-bind:quote="`${ treatment.title[$i18n.locale].replace(/(<([^>]+)>)/ig, '') } | marier Skincare 曼肌兒`"
                                        v-bind:hashtags="`marier,skincare,${ treatment.title[$i18n.locale].replace(/(<([^>]+)>)/ig, '') }`">
                        </share-to-s-n-s>
                    </div>
                </div>

                <div class="col-md-5 pr-md-4 pb-4 pb-md-0"
                     v-if="treatment.images.length > 0"
                     data-aos="fade-in"
                     data-aos-duration="800"
                     data-aos-delay="300">
                    <div class="product-image position-relative text-center">
                        <img class="img-responsive"
                             v-bind:alt="treatment.title[$i18n.locale].replace(/(<([^>]+)>)/ig, '')"
                             v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/salon/treatment/image/${ treatment.images[0] }`">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BackgroundFilter from "../BackgroundFilter";
    import ShareToSNS from "../ShareToSNS";
    export default {
        name: "TreatmentBanner",
        components: {
            ShareToSNS,
            BackgroundFilter
        },
        props: {
            "treatment": {
                type: Object,
                required: true,
            },
        },
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    .banner {
        min-height: 50vh;

        .product-image {
            & > .img-responsive {
                height: auto;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
</style>
