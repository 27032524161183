<template>
    <li class="media py-3 align-items-stretch">
        <div class="item-image-wrapper mr-2"
             v-if="!isTreatment">
            <img class="d-block mx-auto item-image"
                 v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ item.images[0] }`"
                 v-bind:alt="`${ item.title[$i18n.locale] } | ${ item.subtitle[$i18n.locale] }`">
        </div>

        <div class="media-body">
            <div class="row no-gutters h-100 position-relative">
                <div class="col-md-8 d-flex flex-column justify-content-center"
                     v-if="isTreatment">
                    <h5 class="font-weight-bolder mb-1">
                        <router-link v-bind:to="`/treatments/${ item._id }`"
                                     v-html="item.title[$i18n.locale]">
                        </router-link>
                    </h5>

                    <div class="mt-2 mb-1">
                        <p class="d-inline-block mb-0">
                            {{ $t("message.basket.treatments.options") }}
                        </p>
                        <h5 class="d-inline-block mb-0">
                                        <span class="badge badge-dark font-weight-light mr-2"
                                              v-for="sku in item.skus"
                                              v-bind:key="sku._id">
                                            {{ sku.name[$i18n.locale] }}
                                        </span>
                        </h5>
                    </div>
                    <div class="mt-2 mb-1">
                        <p class="d-inline-block mb-0">
                            {{ $t("message.basket.treatments.date") }}
                        </p>
                        <h5 class="d-inline-block mb-0">
                            {{ item.session }} {{ item.date | dateFnsFormatter("yyyy/M/d") }}
                        </h5>
                    </div>
                    <div class="mb-1" v-if="item.branch">
                        <p class="d-inline-block mb-0">
                            {{ $t("message.basket.treatments.branch") }}
                        </p>
                        <h5 class="d-inline-block mb-0">
                            {{ item.branch.address[$i18n.locale] }}
                        </h5>
                    </div>
                </div>
                <div class="col-md-8 d-flex flex-column justify-content-center"
                     v-else>
                    <h5 class="font-weight-bolder mb-1">
                        <router-link v-bind:to="`/products/${ item._id }`"
                                     v-html="item.title[$i18n.locale]">
                        </router-link>
                    </h5>

                    <p class="mb-0"
                       v-html="item.subtitle[$i18n.locale]">
                    </p>

                    <div class="mt-2">
                        <p class="d-inline-block">
                            {{ $t("message.basket.unit-price") }}
                        </p>
                        <h5 class="d-inline-block">
                            <small>HK$</small>{{ item.prices[0].hkd | currency("", 0) }}
                        </h5>
                    </div>
                </div>

                <div class="col-md-4 d-flex flex-column">
                    <div class="flex-2 d-flex flex-column justify-content-center align-items-end align-items-md-center mb-1 mb-md-0"
                         v-if="!isTreatment">
                        <p class="mb-1">
                            {{ $t("message.basket.quantity") }}
                        </p>
                        <item-quantity v-bind:item="item"
                                       v-bind:value="item.quantity">
                        </item-quantity>
                    </div>

                    <div class="flex-2 d-flex flex-column justify-content-center align-items-end align-items-md-center mb-1 mb-md-0">
                        <p class="mb-0">
                            {{ $t("message.basket.sub-total") }}
                        </p>
                        <h5 class="mb-0"
                            v-if="isTreatment">
                            <small>HK$</small>{{ item.skus.reduce((acc, sku) => acc += sku.hkd, 0) | currency("", 2) }}
                        </h5>
                        <h5 class="mb-0"
                            v-else>
                            <small>HK$</small>{{ item.quantity * item.prices[0].hkd | currency("", 2) }}
                        </h5>
                    </div>

                    <div class="flex-1 text-center d-flex justify-content-end justify-content-md-center align-items-center">
                        <button class="btn btn-sm btn-outline-dark rounded-0"
                                v-on:click="deleteItem">
                            {{ $t("message.basket.delete") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import ItemQuantity from "./BasketItem/ItemQuantity";
export default {
    name: "BasketItem",
    components: {
        ItemQuantity,
    },
    props: {
        isTreatment: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {
        async deleteItem () {
            try {
                await this.$store.commit(
                    "cart/deleteItem",
                    {
                        "type": this.isTreatment ? "treatments" : "products",
                        "itemId": this.item._id,
                    }
                );
            } catch (e) {
                console.error(e);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.item-image-wrapper {
    min-width: 120px;

    & > .item-image {
        height: 150px;
        max-width: 100%;
    }
}
</style>
