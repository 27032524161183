import * as VueGoogleMaps from "vue2-google-maps";
import { GoogleMapKey } from "../config";

export default function (Vue) {
    Vue.use(VueGoogleMaps, {
        load: {
            key: GoogleMapKey,
            libraries: "places",
        },
    });
}
