<template>
    <div class="row no-gutters mb-3">
        <div class="col-md-8"></div>
        <div class="col-md-4 d-flex flex-column justify-content-center">
            <template v-if="!coupon">
                <button type="button"
                        class="btn btn-link"
                        v-on:click="showCoupon = !showCoupon">
                    <span>{{ $t("message.basket.have-a-coupon-code") }}</span>
                    <font-awesome-icon v-bind:icon="['fal', 'chevron-down']"></font-awesome-icon>
                </button>
                <b-collapse class="mt-2"
                            v-model="showCoupon">
                    <div class="card">
                        <div class="card-body p-3">
                            <form v-on:submit.prevent.stop="redeem">
                                <b-input-group>
                                    <b-form-input required
                                                  v-bind:placeholder="$t('message.basket.coupon-code')"
                                                  v-model.trim="code">
                                    </b-form-input>
                                    <b-input-group-append>
                                        <button type="submit"
                                                class="btn btn-dark"
                                                v-bind:class="{ 'is-loading': isLoading, }">
                                            {{ $t("message.basket.redeem") }}
                                        </button>
                                    </b-input-group-append>
                                </b-input-group>
                            </form>
                        </div>
                        <h5 class="card-text">

                        </h5>
                    </div>
                </b-collapse>
            </template>
            <template v-else>
                <h6 class="font-weight-bolder">
                    {{ $t("message.basket.coupon-code-applied") }}
                </h6>
                <div class="list-group">
                    <div class="list-group-item px-3 py-2">
                        <strong>{{ coupon.code }}</strong><br>
                        {{ coupon.description }}
                    </div>
                    <div class="list-group-item bg-light p-1 text-right">
                        <button class="btn btn-sm btn-danger"
                                v-on:click="coupon = null">
                            {{ $t("message.basket.delete") }}
                        </button>
                    </div>
                </div>
            </template>
        </div>

        <b-toast solid
                 v-model="isNotLoggedIn">
            <template v-slot:toast-title>
                <strong>
                    請先登入或註冊
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    使用優惠券前，請先登入或註冊。
                </div>
                <div class="d-flex align-items-center">
                    <router-link class="btn btn-sm btn-light border"
                                 to="/login">
                        立即登入
                    </router-link>
                </div>
            </div>
        </b-toast>

        <b-toast solid
                 v-model="isCouponInapplicable">
            <template v-slot:toast-title>
                <strong>
                    優惠券不適用
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    優惠券並不適用該購物籃
                </div>
            </div>
        </b-toast>

        <b-toast solid
                 v-model="isCouponExpired">
            <template v-slot:toast-title>
                <strong>
                    優惠券已過期
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    優惠券已過期，下回請早。
                </div>
            </div>
        </b-toast>

        <b-toast solid
                 v-model="isCouponInvalid">
            <template v-slot:toast-title>
                <strong>
                    優惠券無效
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    優惠券無效，請確定輸入號碼正確。
                </div>
            </div>
        </b-toast>
    </div>
</template>

<script>
import getCouponByCode from "../../api/coupon/getCouponByCode";

import {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCollapse,
    BToast,
} from "bootstrap-vue";

export default {
    name: "BasketCoupon",
    components: {
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BCollapse,
        BToast,
    },
    props: {
        value: {
            type: Object,
        },
        isTreatment: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isLoading: false,
            isNotLoggedIn: false,
            isCouponInapplicable: false,
            isCouponExpired: false,
            isCouponInvalid: false,
            showCoupon: false,
            code: "",
        };
    },
    computed: {
        isLoggedIn () {
            return this.$store.state.user.status === "loggedIn";
        },

        coupon: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            }
        },
    },
    methods: {
        async redeem () {
            if (!this.isLoggedIn) {
                this.isNotLoggedIn = true;
                return;
            }

            this.code = this.code.toUpperCase();
            this.isLoading = true;
            let coupon;
            try {
                coupon = await getCouponByCode(this.code);
            } catch (e) {
                if (e === "coupon expired") {
                    this.isCouponExpired = true;
                    return;
                }
                this.isCouponInvalid = true;
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            if (
                this.isTreatment === true &&
                coupon.shop !== "5b980d38805573081e65f23f"
            ) {
                this.isCouponInapplicable = true;
                return;
            }
            if (
                this.isTreatment === false &&
                coupon.shop !== "5b980d38805573081e65f23e"
            ) {
                this.isCouponInapplicable = true;
                return;
            }
            this.code = "";
            this.coupon = coupon;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
