<template>
    <div>
        <canvas ref="qrious"></canvas>
    </div>
</template>

<script>
    import QRious from "qrious";

    export default {
        name: "QrCode",
        props: {
            "value": {
                type: String,
                required: true
            },

            "size": {
                type: Number,
            },

            "options": {
                type: Object,
                default: () => {}
            }
        },
        data () {
            return {
                qriousInstance: null
            }
        },
        mounted () {
            let config = {
                element: this.$refs.qrious,
                value: this.value,
            };

            if (this.size) {
                config.size = this.size;
            }

            config = Object.assign(config, this.options);
            this.qriousInstance = new QRious(config);
        },
        watch: {
            "value" (newVal) {
                this.qriousInstance.value = newVal;
            },

            "options" (newVal) {
                if (typeof newVal === "object") {
                    this.qriousInstance.set(newVal);
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    canvas {
        height: 100%;
        width: 100%;
    }
</style>
