<template>
    <button class="btn btn-block btn-dark rounded-0"
            v-bind:class="{ 'btn-loading': userStatus === 'loggingIn' }"
            v-on:click="loginByFacebook">
        <font-awesome-icon class="mr-2" v-bind:icon="['fab', 'facebook-square']"></font-awesome-icon>FACEBOOK
    </button>
</template>

<script>
    export default {
        name: "OAuthLogInFacebook",
        computed: {
            userStatus () {
                return this.$store.state.user.status;
            },
        },
        methods: {
            async loginByFacebook () {
                let user;
                user = await new Promise(
                    (resolve) => {
                        FB.login(
                            (res) => {
                                resolve(res);
                            }, {
                                scope: "public_profile,email"
                            }
                        );
                    }
                );

                if (user.status === "connected") {
                    try {
                        await this.$store
                            .dispatch(
                                "user/loginByFacebook",
                                {
                                    "token": user.authResponse.accessToken,
                                    "lang": this.$i18n.locale
                                }
                            );
                    } catch (e) {
                        console.error(e);
                        return;
                    }
                    this.$router.push("/");
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
