import { render, staticRenderFns } from "./ModalForgetPassword.vue?vue&type=template&id=56ba3b3e&scoped=true&"
import script from "./ModalForgetPassword.vue?vue&type=script&lang=js&"
export * from "./ModalForgetPassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ba3b3e",
  null
  
)

export default component.exports