<template>
    <div class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex justify-content-between">
            <div class="flex-3 mr-3">
                <h5 class="font-weight-bold mb-1"
                    v-html="item.title[$i18n.locale]">
                </h5>
                <div class="d-flex justify-content-between flex-nowrap mb-1"
                     v-if="!isTreatment">
                    <p class="mb-0"
                       v-html="item.subtitle[$i18n.locale]">
                    </p>
                    <p class="mb-0">
                        x{{ item.quantity }}
                    </p>
                </div>

                <p class="mb-1" v-else>
                    <span class="badge badge-dark mr-2 mb-1" style="white-space: normal;"
                          v-for="sku in item.skus"
                          v-bind:key="sku._id">
                        {{ sku.name[$i18n.locale] }}
                    </span>
                </p>
            </div>
            <div class="flex-1">
                <h5 class="text-right"
                    v-if="!isTreatment">
                    <small>HK$</small>{{ item.prices[0].hkd * item.quantity | currency("", 2) }}
                </h5>
                <h5 class="text-right"
                    v-else>
                    <small>HK$</small>{{ item.skus.reduce((acc, sku) => acc += sku.hkd, 0) | currency("", 2) }}
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyBasketItem",
    props: {
        isTreatment: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
