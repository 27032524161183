import {
    loadStripe
} from "@stripe/stripe-js";

const stripeInit = {
    install: async function (Vue, apiKey) {
        if (apiKey) {
            Vue.prototype.$stripeLoadPromise = loadStripe(apiKey);
            Vue.prototype.$stripe = await Vue.prototype.$stripeLoadPromise;
        }
    }
};

export default stripeInit;
