<template>
    <aside class="side-bar position-fixed"
           v-bind:class="{ 'open': sideBarOpen }">
        <div class="text-right pt-3 mr-4 mb-3">
            <button type="button"
                    v-on:click="closeSideBar">
                <font-awesome-icon
                    v-bind:icon="['fal', 'times']">
                </font-awesome-icon>
            </button>
        </div>
        <div class="toolbar d-flex justify-content-around align-items-center mx-3 mb-3">
            <a v-on:click="selectLang('en')">
                <span>English</span>
            </a>
            <span>|</span>
            <a v-on:click="selectLang('zh-hk')">
                <span>繁體中文</span>
            </a>
            <span>|</span>
            <a v-on:click="selectLang('zh-cn')">
                <span>简体中文</span>
            </a>
        </div>
        <div class="toolbar d-flex d-md-none justify-content-around align-items-center mx-3 mb-3">
            <router-link to="/login"
                         v-if="userStatus !== 'loggedIn'"
                         v-on:click.native="closeSideBar">
                <font-awesome-icon class="mr-2"
                                   v-bind:icon="['fal', 'user']">
                </font-awesome-icon>
                <span>{{ $t("message.navbar.login-register") }}</span>
            </router-link>
            <router-link to="/account"
                         v-if="userStatus === 'loggedIn'"
                         v-on:click.native="closeSideBar">
                <font-awesome-icon class="mr-2"
                                   v-bind:icon="['fal', 'user']">
                </font-awesome-icon>
                <span>{{ $t("message.navbar.my-account") }}</span>
            </router-link>
            <span v-if="userStatus === 'loggedIn'">|</span>
            <a v-if="userStatus === 'loggedIn'"
               v-on:click="logout">
                <font-awesome-icon class="mr-2"
                                   v-bind:icon="['fal', 'sign-out']">
                </font-awesome-icon>
                <span>{{ $t("message.navbar.sign-out") }}</span>
            </a>
        </div>
        <ul>
            <side-bar-item v-for="menuItem in menu"
                           v-bind:key="menuItem.to"
                           v-bind:title="menuItem.title"
                           v-bind:icons="menuItem.icons"
                           v-bind:to="(menuItem.to) ? menuItem.to : null"
                           v-bind:sub-menu-items="(menuItem.subMenuItems) ? menuItem.subMenuItems : null"
                           v-on:close-side-bar="closeSideBar">
            </side-bar-item>
        </ul>
    </aside>
</template>

<script>
import localForage from "localforage";

import SideBarItem from "./SideBar/SideBarItem";

export default {
    name: "SideBar",
    components: {
        SideBarItem
    },

    computed: {
        sideBarOpen () {
            return this.$store.state.layout.sideBarOpen;
        },

        userStatus () {
            return this.$store.state.user.status;
        },

        categories () {
            return this.$store.state.mall.shop.categories;
        },

        series () {
            return this.$store.state.mall.shop.series;
        },

        menu () {
            return [
                {
                    "title": {
                        "en": "News",
                        "zh-hk": "最新消息",
                        "zh-cn": "最新消息"
                    },
                    "icons": ["fal", "chevron-right"],
                    "to": "/news",
                },
                {
                    "title": {
                        "en": "About marier",
                        "zh-hk": "關於marier",
                        "zh-cn": "关于marier"
                    },
                    "icons": ["fal", "chevron-right"],
                    "to": "/about",
                },
                {
                    "title": {
                        "en": "Promotions",
                        "zh-hk": "限時優惠",
                        "zh-cn": "限时优惠"
                    },
                    "icons": ["fal", "chevron-down"],
                    "subMenuItems": this.$store.getters["mall/campaigns"]
                        .filter(
                            ({ hidden }) => !hidden
                        )
                        .map(
                            ({ title, componentId, }) => ({
                                title,
                                "to": `/campaign/${ componentId }`
                            })
                        )
                },
                {
                    "title": {
                        "en": "Product Series",
                        "zh-hk": "產品系列",
                        "zh-cn": "产品系列"
                    },
                    "icons": ['fal', 'chevron-down'],
                    "subMenuItems": this.$store.getters["mall/shopSeries"]
                        .map(
                            ({ _id, headline, }) => ({
                                "title": headline,
                                "to": {
                                    "name": "product-series",
                                    "params": {
                                        "id": _id
                                    }
                                }
                            })
                        )
                },
                {
                    "title": {
                        "en": "Product Categories",
                        "zh-hk": "產品類型",
                        "zh-cn": "产品类型"
                    },
                    "icons": ['fal', 'chevron-down'],
                    "subMenuItems": [
                        {
                            "title": {
                                "en": "All Categories",
                                "zh-hk": "所有類別",
                                "zh-cn": "所有类别"
                            },
                            "to": {
                                "name": "products",
                            }
                        }
                    ].concat(
                        this.$store.state.mall.shop.categories.map(
                            ({ _id, name, }) => ({
                                "title": name,
                                "to": {
                                    "name": "products",
                                    "query": {
                                        "category": "type",
                                        "subcategory": _id
                                    }
                                }
                            })
                        )
                    )
                },
                {
                    "title": {
                        "en": "Signature Treatments",
                        "zh-hk": "皇牌療程",
                        "zh-cn": "皇牌疗程"
                    },
                    "icons": ['fal', 'chevron-down'],
                    "subMenuItems": this.$store.state.mall.salon.treatments
                        .filter(
                            ({ series }) => series === "5bad92ff6459cc5033483ef0"
                        )
                        .map(
                            ({ _id, title, }) => ({
                                title,
                                "to": {
                                    "name": "treatment-details",
                                    "params": {
                                        "id": _id
                                    }
                                }
                            })
                        )
                },
                {
                    "title": {
                        "en": "Treatment Catalogue",
                        "zh-hk": "療程目錄",
                        "zh-cn": "疗程目录"
                    },
                    "icons": ['fal', "chevron-down"],
                    "subMenuItems": [
                        {
                            "title": {
                                "en": "All Treatments",
                                "zh-hk": "療程一覽",
                                "zh-cn": "疗程一览"
                            },
                            "to": "/treatments"
                        }
                    ].concat(
                        this.$store.getters["mall/salonSeries"].map(
                            ({ _id, title, }) => ({
                                title,
                                "to": {
                                    "name": "treatments",
                                    "hash": `#${ _id }`
                                }
                            })
                        )
                    )
                },
                {
                    "title": {
                        "en": "Blog",
                        "zh-hk": "網誌",
                        "zh-cn": "博客"
                    },
                    "icons": ["fal", "chevron-right"],
                    "to": "/blog",
                },
                {
                    "title": {
                        "en": "Store Locator",
                        "zh-hk": "分店資料",
                        "zh-cn": "分店资料"
                    },
                    "icons": ["fal", "chevron-right"],
                    "to": "/locations",
                },
                {
                    "title": {
                        "en": "Others",
                        "zh-hk": "其他",
                        "zh-cn": "其他"
                    },
                    "icons": ["fal", "chevron-down"],
                    "subMenuItems": [
                        {
                            "title": {
                                "en": "Contact us",
                                "zh-hk": "聯絡我們",
                                "zh-cn": "联络我们"
                            },
                            "to": "/contact"
                        },
                        {
                            "title": {
                                "en": "Privacy Policy",
                                "zh-hk": "私隱政策",
                                "zh-cn": "私隐政策"
                            },
                            "to": "/privacy"
                        },
                        {
                            "title": {
                                "en": "Terms and Conditions",
                                "zh-hk": "條款及細則",
                                "zh-cn": "条款及细则"
                            },
                            "to": "/terms"
                        }
                    ]
                }
            ];
        }
    },
    methods: {
        closeSideBar () {
            this.$store.commit("layout/closeSideBar");
        },
        async selectLang (lang) {
            if (["en", "zh-hk", "zh-cn"].includes(lang)) {
                await localForage.setItem("marier.user-lang", lang);
                this.$i18n.locale = lang;
            }
        },
        logout () {
            this.$store.dispatch("user/logout");
        },
    },
    mounted () {
        document
            .getElementById("app")
            .addEventListener(
                "click",
                (evt) => {
                    if (
                        !evt.target.matches(".side-bar *") &&
                        !evt.target.matches(".menu-nav *")
                    ) {
                        this.$store.commit("layout/closeSideBar");
                    }
                }
            );
    }
}
</script>

<style lang="scss" scoped>
aside.side-bar {
    top: 0;
    left: -375px;
    bottom: 0;

    width: 350px;
    height: 100%;
    background-color: rgba(242, 242, 242, 1);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);

    transition:
        transform 600ms ease-in-out,
        width 800ms ease-in-out;
    will-change: transform;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    z-index: 10;

    &.open {
        transform: translate3d(375px, 0, 0);
    }

    a,
    button {
        border: none;
        outline: none;
        background-color: transparent;
        color: rgba(83, 87, 90, 1);
        font-size: 22px;
        cursor: pointer;

        &:hover {
            color: rgba(59, 61, 63, 1);
            font-weight: bold;
            text-decoration: none;
        }
    }

    & > .toolbar {
        & > a {
            font-size: 18px;
        }
    }

    & > ul {
        list-style: none;
        font-size: 18px;
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    aside.side-bar {
        width: 100%;
        left: -100%;

        box-shadow: none;

        &.open {
            transform: translate3d(100%, 0, 0);
        }
    }
}
</style>
