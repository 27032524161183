import { apiUrl, apiVersion, } from "../../config";

import localforage from "localforage";

export async function verify2FA (
    type,
    code
) {
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/2fa/${ type }`,
        {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            body: JSON.stringify(
                {
                    "token": code
                }
            )
        }
    );

    if (response.ok) {
        const incomingToken = await response.text();
        if (incomingToken) {
            return incomingToken;
        }
        throw "USER Verify 2FA error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default verify2FA;
