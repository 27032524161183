<template>
    <main>
        <nav-bar></nav-bar>
        <side-bar></side-bar>
        <!-- <cart-bar v-bind:open.sync="cartBarOpen"></cart-bar> -->
        <keep-alive include="Register">
            <router-view></router-view>
        </keep-alive>
        <site-map></site-map>
        <footer-bar></footer-bar>
        <back-to-top-button></back-to-top-button>
    </main>
</template>

<script>
    import NavBar from "../components/layout/NavBar";
    import SideBar from "../components/layout/SideBar";
    import SiteMap from "../components/layout/SiteMap";
    import FooterBar from "../components/layout/FooterBar";
    import CartBar from "../components/layout/CartBar";
    import BackToTopButton from "../components/layout/BackToTopButton";
    export default {
        name: "Default",
        components: {
            BackToTopButton,
            CartBar,
            FooterBar,
            SiteMap,
            SideBar,
            NavBar
        },
    }
</script>

<style lang="scss" scoped>
    main {
        width: 100%;
        min-height: 100%;
    }
</style>
