export function getDeliveryClassEligible (
    deliveryClasses,
    address,
) {
    const defaultClass = deliveryClasses.find(
        deliveryClass => deliveryClass.default
    );

    const target = deliveryClasses.find(
        deliveryClass => !!deliveryClass.countries.find(
            ({ _id }) => _id === address.country._id
        )
    );

    if (!target) {
        return  defaultClass;
    }

    return target;
}

export default getDeliveryClassEligible;
