<template>
    <ul class="list-unstyled">
        <li class="empty-notice d-flex justify-content-center align-items-center"
            v-if="cart.length === 0">
            <div class="d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-end">
                <h3 class="mb-md-0 mr-md-2">Your basket is empty</h3>
                <p class="mb-md-0">Make it happy with our products!</p>
            </div>
        </li>
        <basket-item v-for="product in cart"
                     v-bind:key="product._id"
                     v-bind:item="product"
                     v-bind:is-treatment="isTreatment">
        </basket-item>
    </ul>
</template>

<script>
import BasketItem from "./BasketItem";
export default {
    name: "BasketList",
    components: {
        BasketItem,
    },
    props: {
        isTreatment: {
            type: Boolean,
            default: false,
        },
        cart: {
            type: Array,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
ul {
    & > li {
        border-bottom: 1px solid rgba(0, 0, 0, .125);

        &:first-child {
            border-top: 1px solid rgba(0, 0, 0, .125);
        }
    }
}

.empty-notice {
    min-height: 200px;
}
</style>
