<template>
    <b-modal centered
             v-bind:title="$t('message.login.forget-password')"
             v-model="isModalOpen"
             v-on:cancel="closeModal">
        <template>
            <p class="text-justify">{{ $t("message.login.forget-password-line") }}</p>
            <div class="form-row">
                <b-form-group class="col-md-12"
                              label-for="email"
                              v-bind:label="$t('message.registration.email')">
                    <b-form-input id="email"
                                  class="rounded-0"
                                  type="email"
                                  v-model.trim="email">
                    </b-form-input>
                </b-form-group>
            </div>
        </template>
        <template slot="modal-footer">
            <button type="button" class="btn btn-primary"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-bind:disabled="!allowConfirm"
                    v-on:click="forgetPassword">
                {{ $t("message.forget-password.confirm") }}
            </button>
        </template>
    </b-modal>
</template>

<script>
import isEmail from "validator/lib/isEmail";
import forgetPassword from "../../api/user/forgetPassword";

import {
    BFormInput,
    BFormGroup,
    BModal,
} from "bootstrap-vue";

export default {
    name: "ModalForgetPassword",
    components: {
        BFormInput,
        BFormGroup,
        BModal,
    },
    props: {
        value: {
            type: Boolean,
        },
    },
    data () {
        return {
            isLoading: false,

            email: "",
            phone: "",
        };
    },
    computed: {
        isModalOpen: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            },
        },

        allowConfirm () {
            return !!this.email &&
                isEmail(this.email);
        }
    },
    methods: {
        closeModal () {
            this.isModalOpen = false;
        },

        async forgetPassword () {
            this.isLoading = true;

            let userIdentifiers = {};
            if (this.email) {
                userIdentifiers.email = this.email;
            }
            if (this.phone) {
                userIdentifiers.phone = this.phone;
            }

            try {
                await forgetPassword(userIdentifiers);
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.showPasswordResetModal = false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
