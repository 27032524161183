import { apiUrl, apiVersion, } from "../../config";

import localforage from "localforage";

export async function resendVerificationEmail () {
    const token = await localforage.getItem("marier.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/verify`,
        {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${ token }`
            }
        }
    );

    if (response.ok) {
        return;
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default resendVerificationEmail;
