export function calculatePercentageType (
    cart,
    cartTotal,
    customerBuys,
    discountRate,
) {
    if (
        Array.isArray(customerBuys) &&
        customerBuys.length > 0
    ) {
        const intersection = new Set(
            cart
                .filter(
                    x => new Set(customerBuys).has(x._id)
                )
        );
        if (intersection.size === 0) {
            return 0;
        }
        const eligibleSpending = [...intersection]
            .reduce(
                (acc, { prices, quantity }) => {
                    acc += prices[0].hkd * quantity
                    return acc;
                },
                0
            );
        return eligibleSpending * (discountRate / 100);
    }
    return cartTotal * (discountRate / 100);
}

export default calculatePercentageType;
