<template>
    <footer class="footer-bar">
        <div class="container h-100">
            <hr class="my-0">
            <div class="d-flex flex-column justify-content-center h-100">
                <div class="social-networks d-flex justify-content-center justify-content-md-end align-items-center">
                    <a href="https://www.weibo.com/marierskincare"
                       target="_blank"
                       rel="noopener nofollow noreferrer">
                        <font-awesome-icon v-bind:icon="['fab', 'weibo']"></font-awesome-icon>
                    </a>

                    <a v-on:click="showWeixinModal = true">
                        <font-awesome-icon v-bind:icon="['fab', 'weixin']"></font-awesome-icon>
                    </a>

                    <a href="https://www.instagram.com/marierskincarehk"
                       target="_blank"
                       rel="noopener nofollow noreferrer">
                        <font-awesome-icon v-bind:icon="['fab', 'instagram']"></font-awesome-icon>
                    </a>

                    <a href="https://www.facebook.com/marierskincare"
                       target="_blank"
                       rel="noopener nofollow noreferrer">
                        <font-awesome-icon v-bind:icon="['fab', 'facebook-square']"></font-awesome-icon>
                    </a>
                </div>
                <p class="text-center text-md-right">
                    <router-link to="/privacy">{{ $t("message.privacy.title") }}</router-link>｜<router-link to="/terms">{{ $t("message.terms.title") }}</router-link> <br class="d-md-none"> Copyright © {{ new Date().getFullYear() }} marier skincare
                </p>
            </div>
        </div>
        <b-modal hide-header
                 hide-footer
                 centered
                 class="modal-weixin"
                 body-class="p-0"
                 v-model="showWeixinModal">
            <modal-weixin></modal-weixin>
        </b-modal>
    </footer>
</template>

<script>
    import {
        BModal
    } from "bootstrap-vue";

    import ModalWeixin from "./ModalWeixin";
    export default {
        name: "FooterBar",
        components: {
            ModalWeixin,
            BModal
        },
        data () {
            return {
                showWeixinModal: false,
            }
        },
    }
</script>

<style lang="scss" scoped>
    footer.footer-bar {
        background-color: rgba(246, 246, 246, 1);
        color: rgba(113, 113, 113, 1);
        height: 150px;

        .social-networks {
            font-size: 24px;
            margin-bottom: 7.5px;

            & > a {
                cursor: pointer;
                color: rgba(113, 113, 113, 1);
                margin-right: 25px;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: rgba(82, 82, 82, 1);
                }
            }
        }

        p {
            & > a {
                color: inherit;
            }
        }
    }
</style>

<style lang="scss">
    .modal-weixin {
        .modal-content {
            background-color: transparent;
        }
    }
</style>
