<template>
    <section class="site-map">
        <div class="container py-5">
            <div class="row">
                <div class="col-md site-map-item text-center"
                     v-for="(item, index) in siteMap"
                     v-bind:key="index">
                    <router-link v-if="item.to && typeof item.to === 'string'"
                                 v-bind:to="item.to">
                        <h5 class="font-weight-bold"
                            v-html="$sanitize(item.title[$i18n.locale])">
                        </h5>
                    </router-link>

                    <template v-if="Array.isArray(item.to)">
                        <router-link v-for="(subitem, index) in item.to"
                                     v-bind:key="index"
                                     v-bind:to="subitem">
                            <h5 class="font-weight-bold"
                                v-html="$sanitize(item.title[index][$i18n.locale])">
                            </h5>
                        </router-link>
                    </template>

                    <h5 class="d-none d-md-block font-weight-bold"
                        v-if="!item.to">
                        {{ $sanitize(item.title[$i18n.locale]) }}
                    </h5>

                    <h5 class="d-md-none font-weight-bold is-cursor-pointer"
                        v-if="!item.to"
                        v-on:click="sectionCollapses[item.id] = !sectionCollapses[item.id]">
                        {{ $sanitize(item.title[$i18n.locale]) }}
                    </h5>

                    <b-collapse v-if="item.children"
                                v-bind:visible="!isScreenSmallerThanMd || sectionCollapses[item.id]">
                        <router-link v-for="(child, index) in item.children"
                                     v-bind:key="index"
                                     v-bind:to="child.to">
                            <p v-html="$sanitize(child.title[$i18n.locale])"></p>
                        </router-link>
                    </b-collapse>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { BCollapse, } from "bootstrap-vue";
export default {
    name: "SiteMap",
    components: {
        BCollapse,
    },
    data () {
        return {
            windowWidth: window.innerWidth,

            sectionCollapses: {
                about: false,
                products: false,
                treatments: false,
                promotions: false,
                others: false,
            }
        }
    },
    computed: {
        campaigns () {
            return this.$store.getters["mall/campaigns"];
        },
        siteMap () {
            return [{
                title: {
                    "en": "Home",
                    "zh-hk": "主頁",
                    "zh-cn": "主頁"
                },
                to: "/",
            }, {
                title: {
                    "en": "News",
                    "zh-hk": "最新消息",
                    "zh-cn": "最新消息"
                },
                to: "/news",
            }, {
                id: "about",
                title: {
                    "en": "About marier",
                    "zh-hk": "關於 marier",
                    "zh-cn": "关于 marier"
                },
                children: [{
                    title: {
                        "en": "Brand Story",
                        "zh-hk": "品牌介紹",
                        "zh-cn": "品牌介绍"
                    },
                    to: "/about",
                }, {
                    title: {
                        "en": "Store Locator",
                        "zh-hk": "分店資料",
                        "zh-cn": "分店资料"
                    },
                    to: "/locations",
                }]
            }, {
                id: "products",
                title: {
                    "en": "Products",
                    "zh-hk": "產品",
                    "zh-cn": "产品"
                },
                children: [{
                    title: {
                        "en": "All Categories",
                        "zh-hk": "所有類別",
                        "zh-cn": "所有类别"
                    },
                    to: "/products",
                }].concat(this.$store.state.mall.shop.series.map(
                    series => ({
                        title: series.headline,
                        to: `/series/${ series._id }`
                    })
                ))
            }, {
                id: "treatments",
                title: {
                    "en": "Treatments",
                    "zh-hk": "療程",
                    "zh-cn": "疗程"
                },
                children: [{
                    title: {
                        "en": "All Treatments",
                        "zh-hk": "療程一覽",
                        "zh-cn": "疗程一览"
                    },
                    to: {
                        name: "treatments",
                    }
                }].concat(this.$store.state.mall.salon.series.map(
                    series => ({
                        title: series.title,
                        to: `/treatments/#${ series._id }`
                    })
                ))
            }, {
                id: "promotions",
                title: {
                    "en": "Promotions",
                    "zh-hk": "最新推廣",
                    "zh-cn": "最新推广"
                },
                "children": Array.isArray(this.campaigns) ?
                    this.campaigns
                        .filter(
                            campaign => !campaign.hidden
                        )
                        .map(
                            campaign => ({
                                "title": campaign.title,
                                "to": `/campaign/${ campaign.componentId }`
                            })
                        )
                        .concat([{
                            "title": {
                                "en": "Treatment FAQ",
                                "zh-hk": "療程FAQ",
                                "zh-cn": "疗程FAQ"
                            },
                            "to": "/treatment-faq"
                        }])
                    :
                    [{
                        "title": {
                            "en": "Treatment FAQ",
                            "zh-hk": "療程FAQ",
                            "zh-cn": "疗程FAQ"
                        },
                        "to": "/treatment-faq"
                    }]
            }, {
                id: "others",
                title: {
                    "en": "Others",
                    "zh-hk": "其他",
                    "zh-cn": "其他"
                },
                children: [{
                    title: {
                        "en": "Contact us",
                        "zh-hk": "聯絡我們",
                        "zh-cn": "联络我们"
                    },
                    to: "/contact",
                }, {
                    title: {
                        "en": "Privacy Policy",
                        "zh-hk": "私隱政策",
                        "zh-cn": "私隐政策"
                    },
                    to: "/privacy",
                }, {
                    title: {
                        "en": "Terms and Conditions",
                        "zh-hk": "條款及細則",
                        "zh-cn": "条款及细则"
                    },
                    to: "/terms",
                }, {
                    title: {
                        "en": "Payment Methods",
                        "zh-hk": "付款方法",
                        "zh-cn": "付款方法"
                    },
                    to: "/payment-methods",
                }, {
                    title: {
                        "en": "Online Shopping Terms and Conditions",
                        "zh-hk": "線上購物條款",
                        "zh-cn": "线上购物条款"
                    },
                    to: "/online-shopping-terms",
                }, {
                    title: {
                        "en": "Order Terms and Delivery Services",
                        "zh-hk": "訂購條款及送貨服務",
                        "zh-cn": "订购条款及送货服务"
                    },
                    to: "/delivery-methods",
                }]
            }];
        },

        isScreenSmallerThanMd () {
            return this.windowWidth < 768;
        }
    },
    methods: {

    },
    mounted () {
        this.$nextTick(() => {
            window.addEventListener("resize", () => {
                this.windowWidth = window.innerWidth;
            });
        })
    }
}
</script>

<style lang="scss" scoped>
section.site-map {
    padding-top: 50px;
    padding-bottom: 35px;
    background-color: rgba(246, 246, 246, 1);
    color: rgba(83, 87, 90, 1);

    .site-map-item {
        h5 {
            font-size: 16px;
            margin-bottom: 22px;
        }

        p {
            font-size: 12px;
        }

        a {
            color: rgba(83, 87, 90, 1);

            &:hover,
            &.active {
                color: rgba(59, 61, 63, 1);
            }
        }

        .is-cursor-pointer {
            cursor: pointer;
        }
    }
}
</style>
