<template>
    <swiper class="headlines"
            ref="carouselSwiper"
            v-bind:options="swiperOptions">
        <swiper-slide class="h-100 w-100">
            <jumbotron class="headline h-100 w-100"
                       v-bind:bg-filter-styles="headlineFilterStyles">
                <template slot="bg-clip">
                    <div class="position-absolute clip-wrapper"
                         data-aos="fade-up"
                         data-aos-duration="1000"
                         data-aos-delay="900">
                        <img id="clip" src="../../assets/home_bg_clip.png">
                    </div>
                </template>
                <h1 class="mb-4 mb-md-5 text-white font-weight-light"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                    data-aos-delay="300">
                <span v-bind:class="$i18n.locale">
                    {{ $t("message.home.tag-line-1") }}
                </span>
                </h1>
                <h1 class="text-white font-weight-bold"
                    data-aos="fade-in"
                    data-aos-duration="1000"
                    data-aos-delay="700">
                <span v-bind:class="$i18n.locale">
                    {{ $t("message.home.tag-line-2") }}
                </span>
                </h1>
            </jumbotron>
        </swiper-slide>

        <!-- <swiper-slide class="h-100 w-100" v-for="banner in banners">

        </swiper-slide> -->

        <button class="position-absolute d-flex justify-content-center align-items-center carousel-control prev p-0"
                slot="button-prev"
                v-on:click="swiper.slidePrev()">
            <font-awesome-icon v-bind:icon="['far', 'arrow-left']"></font-awesome-icon>
        </button>
        <button class="position-absolute d-flex justify-content-center align-items-center carousel-control next p-0"
                slot="button-next"
                v-on:click="swiper.slideNext()">
            <font-awesome-icon v-bind:icon="['far', 'arrow-right']"></font-awesome-icon>
        </button>
    </swiper>
</template>

<script>
    import Jumbotron from "./Jumbotron";

    import "swiper/css/swiper.min.css";
    import {
        Swiper,
        SwiperSlide,
    } from "vue-awesome-swiper";

    export default {
        name: "Carousel",
        components: {
            Jumbotron,
            Swiper,
            SwiperSlide,
        },
        props: {
            banners: {
                type: Array,

            }
        },
        data () {
            return {
                swiperOptions: {
                    spaceBetween: 0,
                    effect: "slide",
                    slidesPerView: 1,
                    loop: true,
                },
            };
        },
        computed: {
            swiper () {
                return this.$refs.carouselSwiper.swiper;
            },

            headlineFilterStyles () {
                return {
                    backgroundImage: "url('./home_bg.png')",
                    backgroundPosition: "center",
                };
            },
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/_breakpoints";

    .carousel-control {
        border: none;
        outline: none;

        background-color: rgba(0, 0, 0, 0.45);
        color: rgba(255, 255, 255, 1);

        border-radius: 0.25rem;

        height: 42px;
        width: 42px;

        cursor: pointer;

        z-index: 2;

        &.prev {
            left: 5px;
            top: 50%;
        }

        &.next {
            right: 5px;
            top: 50%;
        }
    }

    @mixin md {
        @media (max-width: 767px) {
            @content;
        }
    }

    @mixin sm {
        @media (max-width: 575px) {
            @content;
        }
    }

    .headlines {
        background-color: rgba(113, 113, 113, 1);
        height: 100vh;

        .headline {
            .clip-wrapper {
                bottom: 0;
                right: 0;

                @include md {
                    right: -25%;
                }

                @include sm {
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                }

                & > img {
                    height: 40vh;
                    width: auto;

                    @include md {
                        height: 35vh;
                    }

                    @include sm {
                        height: 30vh;
                    }
                }
            }

            h1 {
                & > span {
                    &.zh-hk,
                    &.zh-cn {
                        letter-spacing: 25px;

                        @include md {
                            letter-spacing: 15px;
                        }

                        @include sm {
                            letter-spacing: 7.5px;
                        }
                    }

                    @include md {
                        font-size: 32px;
                    }

                    @include sm {
                        font-size: 26px;
                    }
                }
            }
        }
    }
</style>
