<template>
    <div class="row no-gutters">
        <div class="col-md-8"></div>
        <div class="col-md-4">
            <router-link class="btn btn-dark btn-lg btn-block"
                         v-if="userStatus !== 'loggedIn' || !user.verified"
                         v-bind:to="{ name: 'login', query: { to: $route.path } }">
                {{ $t("message.basket.login-verification-required") }}
            </router-link>

            <button type="button"
                    class="btn btn-dark btn-block"
                    v-else
                    v-bind:disabled="userStatus !== 'loggedIn' || !user.verified || !cart || cart.length === 0"
                    v-on:click="checkout">
                {{ $t("message.checkout.title") }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "BasketCheckout",
    props: {
        isTreatment: {
            type: Boolean,
            default: false,
        },
        cart: {
            type: Array,
        },
        coupon: {
            type: Object,
        },
    },
    data() {
        return {

        };
    },
    computed: {
        user () {
            return this.$store.state.user.user;
        },
        userStatus () {
            return this.$store.state.user.status;
        },
    },
    methods: {
        checkout () {
            const type = this.isTreatment === true ? "treatments" : "products";
            this.$router.push(`/checkout/${ type }/delivery`);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
