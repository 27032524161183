<template>
    <li class="side-bar-item">
        <div class="side-bar-item-wrapper"
             v-if="to"
             v-on:click.stop="navigate">
            <router-link class="position-relative d-flex justify-content-between side-bar-link"
                         v-bind:to="to">
                <span>{{ $sanitize(title[$i18n.locale]) }}</span>
                <font-awesome-icon v-bind:class="{ 'sub-menu-active': !to && subMenuOpen }"
                                   v-bind:icon="icons">
                </font-awesome-icon>
            </router-link>
        </div>
        <div class="position-relative d-flex justify-content-between align-items-center side-bar-item-wrapper"
             v-else
             v-on:click="toggleSubMenu">
            <span>{{ $sanitize(title[$i18n.locale]) }}</span>
            <font-awesome-icon v-bind:class="{ 'sub-menu-active': !to && subMenuOpen }"
                               v-bind:icon="icons">
            </font-awesome-icon>
        </div>
        <ul class="sub-menu"
            v-if="subMenuItems"
            v-bind:class="{ 'active': subMenuOpen }"
            v-on:click="navigate">
            <li v-for="subMenuItem in subMenuItems"
                v-bind:key="subMenuItem.title['en']"
                v-on:click.stop="navigate">
                <router-link class="d-block side-bar-item-wrapper"
                             v-bind:to="subMenuItem.to">
                    {{ $sanitize(subMenuItem.title[$i18n.locale]) }}
                </router-link>
            </li>
        </ul>
    </li>
</template>

<script>
    export default {
        name: "SideBarItem",
        props: {
            open: {
                type: Boolean,
                default: false,
            },
            title: {
                type: Object,
            },
            icons: {
                type: Array,
            },
            subMenuItems: {
                type: Array
            },
            to: {
                type: [String, Object]
            }
        },
        data () {
            return {
                subMenuOpen: false
            }
        },
        methods: {
            toggleSubMenu () {
                this.subMenuOpen = !this.subMenuOpen;
            },

            /*navigate () {
                if (this.subMenuItems) {
                    this.toggleSubMenu();
                } else if (this.to) {
                    this.$emit("close-side-bar");
                    this.$router.push(this.to);
                }
            },*/

            navigate () {
                this.$store.commit("layout/closeSideBar");
            },
        }
    }
</script>

<style lang="scss" scoped>
    li.side-bar-item {
        color: rgba(83, 87, 90, 1);
        padding-top: 20px;
        cursor: pointer;

        & > .side-bar-item-wrapper {
            padding-left: 40px;
            padding-bottom: 15px;
            padding-right: 25px;

            & > .side-bar-link {
                color: rgba(113, 113, 113, 1);
            }

            span {
                &:first-child {
                    padding-bottom: 7.5px;
                    border-bottom: solid 1px rgba(83, 87, 90, 1);
                }
            }

            svg {
                &:last-child {
                    font-size: 22px;
                    transition: transform 200ms ease-in-out;

                    &.sub-menu-active {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &:hover {
            & > .side-bar-item-wrapper {
                & > .side-bar-link {
                    font-weight: bold;
                    text-decoration: none;
                }

                span,
                svg {
                    font-weight: bold;
                    text-decoration: none;
                }
            }
        }

        & > ul.sub-menu {
            list-style: none;

            width: 100%;
            max-height: 0;
            padding-left: 0;

            background-color: rgba(255, 255, 255, 1);
            transition: max-height 400ms ease-in-out;
            overflow: hidden;

            &.active {
                max-height: 1000px;
            }

            & > li {
                &:hover {
                    font-weight: bold;
                    background-color: rgba(0, 0, 0, 0.05);
                }

                & > .side-bar-item-wrapper {
                    padding-top: 12.5px;
                    padding-left: 70px;
                    padding-bottom: 12.5px;
                    color: rgba(113, 113, 113, 1);

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
</style>
