import NProgress from "nprogress/nprogress";

import {
    getWallet,

    addAddress,
    deleteAddress,

    watchPaymentSession,
} from "../workers/wallet.worker";

import addCard from "../api/wallet/addCard";
import deleteCard from "../api/wallet/deleteCard";

import addPaymentSession from "../api/wallet/addPaymentSession";

const WalletStore = {
    namespaced: true,
    state: {
        balance: {
            hkd: 0
        },
        transactions: [],
        sources: [],
        addresses: [],
        paymentSession: {
            delivery: {},
            payment: {},
        }
    },
    mutations: {
        setWallet (
            state,
            { balance, transactions, sources, addresses }
        ) {
            state.balance = balance;
            state.transactions = transactions;

            sources = sources.map(
                card => {
                    card.selected = false;
                    return card;
                }
            );

            state.sources = sources;

            addresses = addresses.map(
                address => {
                    address.selected = false;
                    return address;
                }
            );
            state.addresses = addresses;
        },

        setPaymentSession (
            state,
            sessions
        ) {
            state.paymentSession = sessions;
        },

        addAddress (
            state,
            address
        ) {
            state.addresses.unshift(address);
        },

        deleteAddress (
            state,
            addressId
        ) {
            state.addresses.splice(state.addresses.findIndex(address => address._id === addressId), 1);
        },

        setSources (
            state,
            sources
        ) {
            sources = sources.map(
                card => {
                    card.selected = false;
                    return card;
                }
            );
            state.sources = sources;
        },

        addCard (
            state,
            card
        ) {
            state.sources.unshift(card);
        },

        deleteCard (
            state,
            cardId
        ) {
            state.sources
                .splice(
                    state.sources
                        .findIndex(
                            card => card._id === cardId
                        ),
                    1
                );
        },
    },
    actions: {
        async loadWallet (
            { commit }
        ) {
            let wallet;
            NProgress.start();
            try {
                wallet = await getWallet();
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "setWallet",
                wallet
            );
            NProgress.done();
            return wallet;
        },

        loadPaymentSession (
            context
        ) {
            let session = {};
            if (localStorage.getItem("marier.payment-session")) {
                session = JSON.parse(localStorage.getItem("marier.payment-session"));
            }
            context.commit("setPaymentSession", session);
            return session;
        },

        async addAddress (
            { commit },
            address
        ) {
            let result;
            NProgress.start();
            try {
                result = await addAddress(
                    address
                );
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "addAddress",
                Object.assign(
                    { selected: false },
                    address
                )
            );
            NProgress.done();
            return address;
        },

        async deleteAddress (
            { commit },
            addressId
        ) {
            let result;
            NProgress.start();
            try {
                result = await deleteAddress(
                    addressId
                );
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "deleteAddress",
                addressId
            );
            NProgress.done();
        },

        async addCard (
            { commit },
            sourceId
        ) {
            let wallet;
            NProgress.start();
            try {
                wallet = await addCard(
                    sourceId
                );
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "setWallet",
                wallet
            );
            NProgress.done();
            return wallet;
        },

        async deleteCard (
            { commit },
            cardId
        ) {
            NProgress.start();
            try {
                await deleteCard(
                    cardId
                );
            } catch (e) {
                NProgress.done();
                console.error(e);
                throw e;
            }
            commit(
                "deleteCard",
                cardId
            );
            NProgress.done();
        },

        async addSession (
            context,
            session
        ) {
            let result;
            try {
                result = await addPaymentSession(session);
            } catch (e) {
                console.error(e);
                throw e;
            }
            return result;
        },

        async listenToPaymentSession (
            context,
            sessionId
        ) {
            let result;
            try {
                result = await watchPaymentSession(sessionId);
            } catch (e) {
                console.error(e);
                throw e;
            }
            return result;
        },

        setLocalSessionDeliveryOptions (
            context,
            deliveryOptions
        ) {
            let session = JSON.parse(
                localStorage.getItem("marier.payment-session")
            );

            if (!session) {
                session = {
                    delivery: {},
                    payment: {},
                };
            }

            if (!session.delivery) {
                session.delivery = {};
            }

            for (const option in deliveryOptions) {
                session.delivery[option] = deliveryOptions[option];
            }

            console.log(session);

            localStorage.setItem(
                "marier.payment-session",
                JSON.stringify(session)
            );

            context.commit(
                "setPaymentSession",
                session
            );
        },

        setLocalSessionPaymentOptions (
            context,
            paymentOptions
        ) {
            let session = JSON.parse(
                localStorage.getItem("marier.payment-session")
            );

            if (!session) {
                session = {
                    delivery: {},
                    payment: {},
                };
            }
            if (!session.payment) {
                session.payment = {};
            }

            for (const option in paymentOptions) {
                session.payment[option] = paymentOptions[option];
            }

            localStorage.setItem(
                "marier.payment-session",
                JSON.stringify(session)
            );

            context.commit(
                "setPaymentSession",
                session
            );
        },

        clearPaymentSession (context) {
            context.commit("setPaymentSession", {});
        }
    }
};

export default WalletStore;
