<template>
    <div class="campaign-index-wrapper">
        <component v-bind:is="requiredComponentInstance"
                   v-bind="componentProps">
        </component>
    </div>
</template>

<script>
import _string from "lodash/string";
export default {
    name: "CampaignIndex",
    props: {
        "id": {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            campaign: null,
            componentProps: {
                treatmentId: null,
            }
        }
    },
    computed: {
        requiredComponentInstance () {
            if (this.campaign) {
                let name = _string.upperFirst(_string.camelCase(this.id));
                this.componentProps.treatmentId = this.campaign.treatmentId;
                return () => import(`./${ name }`);
            }
        },
    },
    methods: {

    },
    mounted () {
        if (Array.isArray(this.$store.state.mall.campaigns) && this.$store.state.mall.campaigns.length > 0) {
            let target = this.$store.state.mall.campaigns.find(
                campaign => campaign.componentId === this.id
            );
            if (target) {
                this.campaign = target;
                return;
            }
            this.$router.push({
                name: "home"
            });
        }
    },
    watch: {
        "$store.state.mall.campaigns": {
            handler (newVal, oldVal) {
                if (Array.isArray(newVal) && newVal.length > 0) {
                    let target = newVal.find(
                        campaign => campaign.componentId === this.id
                    );
                    if (target) {
                        this.campaign = target;
                        return;
                    }
                    this.$router.push({
                        name: "home"
                    });
                }
            },
            deep: true
        },

        "id" (newVal, oldVal) {
            if (newVal) {
                let target = this.$store.state.mall.campaigns.find(
                    campaign => campaign.componentId === this.id
                );
                if (target) {
                    this.campaign = target;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.campaign-index-wrapper {
    min-height: 100vh;
}
</style>
