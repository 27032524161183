<template>
    <section class="view login">
        <view-title v-bind:title="$t('message.login.title')"></view-title>
        <div class="container pt-2 pb-5">
            <div class="row">
                <div class="col-md-6 px-0 py-4 d-flex flex-column">
                    <p class="lead mx-4 py-4 text-center font-weight-bold">
                        {{ $t("message.login.register") }}
                    </p>
                    <div class="wrapper h-100 pt-3 px-5">
                        <p class="text-center mb-4">
                            {{ $t("message.login.register-line") }}
                        </p>
                        <router-link class="btn btn-block btn-lg btn-dark rounded-0"
                                     v-bind:to="{ name: 'register', query: $route.query }">
                            {{ $t("message.login.register") }}
                        </router-link>
                    </div>
                </div>
                <div class="col-md-6 px-0 py-4 d-flex flex-column">
                    <p class="lead mx-4 py-4 text-center font-weight-bold">
                        {{ $t("message.login.login") }}
                    </p>
                    <div class="wrapper h-100 pt-3 px-5">
                        <p class="text-center mb-4">
                            {{ $t("message.login.login-sns") }}
                        </p>
                        <div class="row">
                            <div class="col-lg-6 mb-3 mb-lg-0">
                                <o-auth-log-in-facebook></o-auth-log-in-facebook>
                            </div>
                            <div class="col-lg-6 mb-3 mb-lg-0">
                                <o-auth-log-in-google></o-auth-log-in-google>
                            </div>
                        </div>
                        <div class="mt-4 mb-0">
                            <p class="text-center m-0">
                                {{ $t("message.login.login-sns-notice-1") }}<br>
                                <a href="/privacy" target="_blank">{{ $t("message.login.login-sns-notice-2") }}</a>{{ $t("message.login.login-sns-notice-and") }}<a href="/terms" target="_blank">{{ $t("message.login.login-sns-notice-3") }}</a>
                            </p>
                        </div>
                        <div class="d-flex flex-row align-items-center mt-3 mb-4">
                            <div class="bar"></div>
                            <p class="m-0 mx-3">
                                {{ $t("message.login.login-sns-or") }}
                            </p>
                            <div class="bar"></div>
                        </div>
                        <div class="btn-group btn-group-sm w-100 mb-3">
                            <button type="button" class="btn col rounded-0"
                                    v-bind:class="{ 'btn-dark': mode === 'email', 'btn-outline-secondary': mode !== 'email' }"
                                    v-on:click="mode = 'email'">
                                {{ $t("message.login.email") }}
                            </button>
                            <button type="button" class="btn col rounded-0"
                                    v-bind:class="{ 'btn-dark': mode === 'phone', 'btn-outline-secondary': mode !== 'phone' }"
                                    v-on:click="mode = 'phone'">
                                {{ $t("message.login.phone") }}
                            </button>
                        </div>
                        <b-form class="pb-4"
                                v-on:submit="login">
                            <b-form-group label-for="usernameInput"
                                          v-if="mode === 'email'"
                                          v-bind:label="$t('message.login.email')"
                                          v-bind:state="isUsernameValid"
                                          v-bind:invalid-feedback="invalidUsernameFeedback"
                                          v-bind:valid-feedback="validFeedback">
                                <b-form-input id="usernameInput"
                                              class="rounded-0"
                                              type="email"
                                              required
                                              v-bind:class="{ 'animate-shake': userStatus === 'loginFailed' }"
                                              v-model="username"
                                              v-on:input="userStatusInValue = null">
                                </b-form-input>
                            </b-form-group>

                            <div class="form-row m-0" v-if="mode === 'phone'">
                                <b-form-group v-bind:label="$t('message.login.region-code')">
                                    <b-form-select class="mr-sm-2 rounded-0"
                                                   required
                                                   v-model="countryCode">
                                        <option v-bind:value="'+852'">+852</option>
                                        <option v-bind:value="'+853'">+853</option>
                                        <option v-bind:value="'+86'">+86</option>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group class="col"
                                              label-for="phoneInput"
                                              v-bind:label="$t('message.login.phone')"
                                              v-bind:state="isUsernameValid"
                                              v-bind:invalid-feedback="invalidUsernameFeedback"
                                              v-bind:valid-feedback="validFeedback">
                                    <b-form-input id="phoneInput"
                                                  class="rounded-0"
                                                  type="tel"
                                                  required
                                                  pattern="[0-9\-]+"
                                                  v-bind:class="{ 'animate-shake': userStatus === 'loginFailed' }"
                                                  v-model="username"
                                                  v-on:input="userStatusInValue = null">
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <b-form-group label-for="passwordInput"
                                          v-bind:label="$t('message.login.password')"
                                          v-bind:state="isPasswordValid"
                                          v-bind:invalid-feedback="invalidPasswordFeedback"
                                          v-bind:valid-feedback="validFeedback">
                                <b-form-input id="passwordInput"
                                              class="rounded-0"
                                              type="password"
                                              required
                                              v-bind:class="{ 'animate-shake': userStatus === 'loginFailed' }"
                                              v-model="password"
                                              v-on:input="userStatusInValue = null">
                                </b-form-input>
                            </b-form-group>

                            <button class="btn btn-block btn-lg btn-dark rounded-0"
                                    type="submit"
                                    v-bind:class="{ 'btn-loading': userStatus === 'loggingIn' }">
                                {{ $t("message.login.login") }}
                            </button>
                        </b-form>

                        <div class="text-center">
                            <button class="btn btn-link"
                                    v-on:click="showPasswordResetModal = true">
                                {{ $t("message.login.forget-password") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-forget-password v-model="showPasswordResetModal"></modal-forget-password>
        <modal-verify-o-t-p v-model="showCheck2FATokenModal"></modal-verify-o-t-p>
    </section>
</template>

<script>
    import {
        BFormSelect,
        BFormInput,
        BFormGroup,
        BForm,
    } from "bootstrap-vue";

    import isEmail from "validator/lib/isEmail";
    import ViewTitle from "../components/ViewTitle";
    import OAuthLogInGoogle from "../components/OAuthLogInGoogle";
    import OAuthLogInFacebook from "../components/OAuthLogInFacebook";
    import ModalForgetPassword from "../components/modals/ModalForgetPassword";
    import ModalVerifyOTP from "../components/modals/ModalVerifyOTP";

    export default {
        name: "Login",
        components: {
            ModalVerifyOTP,
            ModalForgetPassword,
            OAuthLogInFacebook,
            OAuthLogInGoogle,
            ViewTitle,
            BFormSelect,
            BFormInput,
            BFormGroup,
            BForm,
        },
        metaInfo: {
            title: "註冊/登入",
        },
        data () {
            return {
                isLoading: false,

                state: true,
                showPasswordResetModal: false,
                showCheck2FATokenModal: false,
                validateSubmission: false,

                userStatusInValue: "",

                countryCode: "+852",
                mode: "email",
                username: "",
                password: "",

                otpToken: "",

                forgetPasswordEmail: "",
                forgetPasswordPhone: "",
            }
        },
        computed: {
            userStatus () {
                return this.$store.state.user.status;
            },

            allowLogin () {
                return this.mode === "email" &&
                    isEmail(this.username) &&
                    this.password ||
                    this.mode === "phone" &&
                    this.username.length === 8 &&
                    this.password;
            },

            isUsernameValid () {
                if (this.userStatusInValue === "loginFailed") {
                    return false;
                }

                if (this.mode === "email") {
                    if (this.username.length === 0) {
                        return null;
                    }
                    return isEmail(this.username);
                }

                if (this.mode === "phone") {
                    if (this.username.length === 0) {
                        return null;
                    }
                    return this.countryCode && this.username.length === 8;
                }

                return null;
            },

            invalidUsernameFeedback () {
                if (this.userStatusInValue === "loginFailed") {
                    return this.$t("message.login.incorrect-login");
                }

                if (this.mode === "email") {
                    if (isEmail(this.username)) {
                        return "";
                    }
                    return this.$t("message.login.invalid-email");
                }

                if (this.mode === "phone") {
                    if (this.countryCode && this.username.length === 8) {
                        return "";
                    }
                    return this.$t("message.login.invalid-phone");
                }

                return null;
            },

            isPasswordValid () {
                if (this.userStatusInValue === "loginFailed") {
                    return false;
                }

                return null;
            },

            invalidPasswordFeedback () {
                if (this.userStatusInValue === "loginFailed") {
                    return this.$t("message.login.incorrect-login");
                }
                if (this.password) {
                    return "";
                }
                return this.$t("message.login.invalid-password");
            },

            validFeedback () {
                return "";
            },
        },
        methods: {
            async login (evt) {
                this.validateSubmission = true;
                evt.preventDefault();

                let temp = {
                    mode: this.mode,
                    password: this.password
                };
                switch (this.mode) {
                    case "email": {
                        temp.email = this.username;
                        break;
                    }
                    case "phone": {
                        temp.countryCode = this.countryCode;
                        temp.phone = this.username;
                        break;
                    }
                }

                try {
                    await this.$store
                        .dispatch(
                            "user/login",
                            temp
                        );
                } catch (e) {
                    console.error(e);

                    if (this.userStatus === "loginFailed") {
                        this.reset();
                    }

                    if (this.userStatus === "verifying2FA") {
                        this.showCheck2FATokenModal = true;
                    }
                }
            },

            reset () {
                this.countryCode = "+852";
                this.username = "";
                this.password = "";
            }
        },
        async mounted () {
            if (this.userStatus === "loggedIn") {
                const target = {
                    "path": "/"
                };
                if (this.$route.query.to) {
                    target.path = this.$route.query.to;
                }
                this.$router.push(target);
            }
        },
        watch: {
            "userStatus" (newVal) {
                this.userStatusInValue = newVal;
                if (newVal === "loggedIn") {
                    const target = {
                        "path": "/"
                    };
                    if (this.$route.query.to) {
                        target.path = this.$route.query.to;
                    }
                    this.$router.push(target);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .login {
        .container {
            & > .row {
                color: rgba(113, 113, 113, 1);

                & > .col-md-6 {
                    & > p {
                        border-bottom: solid 1px rgba(185, 182, 180, 1);
                    }

                    &:first-child {
                        & > .wrapper {
                            border-right: solid 1px rgba(185, 182, 180, 1);
                        }
                    }

                    .btn-group {

                    }
                }
            }
        }

        .bar {
            flex: 3;
            border: 0;
            border-top: 1px solid rgba(185, 182, 180, 1);
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        .btn-link {
            color: rgba(113, 113, 113, 1);
        }
    }

    .verify-otp {
        max-width: 280px;
    }

    .animate-shake {
        animation-iteration-count: 1;
        animation-duration: 500ms;
        animation-fill-mode: both;
        animation-name: shake;
    }

    @keyframes shake {
        from,
        to {
            transform: translate3d(0, 0, 0);
        }

        10%,
        30%,
        50%,
        70%,
        90% {
            transform: translate3d(-10px, 0, 0);
        }

        20%,
        40%,
        60%,
        80% {
            transform: translate3d(10px, 0, 0);
        }
    }

    .shake {
        animation-name: shake;
    }
</style>
