import calculatePercentageType from "./types/percentage";
import calculateFixedAmountType from "./types/fixedAmount";
import calculateBuyXGetYType from "./types/buyXGetY";

export function calculateDeductibleByCoupon (
    cart,
    cartTotal,
    {
        _id, type,
        customerBuys,
        discountRate, discountAmount,
        buyQuantity, buyAmount, customerGets, getQuantity, getDiscount, maxUsePerOrder,
    },
) {
    console.log(cart);

    if (type === "percentage") {
        return calculatePercentageType(
            cart,
            cartTotal,
            customerBuys,
            discountRate,
        );
    }

    if (type === "fixed-amount") {
        return calculateFixedAmountType(
            cart,
            customerBuys,
            discountAmount,
        );
    }

    if (type === "buy-x-get-y") {
        return calculateBuyXGetYType(
            cart,
            customerBuys,
            buyQuantity,
            buyAmount,
            customerGets,
            getQuantity,
            getDiscount,
            maxUsePerOrder
        );
    }

    throw "incorrect type";
}

export default calculateDeductibleByCoupon;
