<template>
    <div class="background position-absolute">
        <picture>
            <source v-for="(value, mime) in sources"
                    v-bind:key="value"
                    v-bind:type="mime"
                    v-bind:srcset="value">
            <img alt="background"
                 v-bind:src="images.default">
        </picture>
    </div>
</template>

<script>
    export default {
        name: "Background",
        props: {
            images: {
                type: Object,
                required: true
            }
        },
        data () {
            return {

            }
        },
        computed: {
            sources () {
                const temp = Object.assign({}, this.images);
                delete temp.default;
                return temp;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .background {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;

        & > picture {
            & > img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
</style>
