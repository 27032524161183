export default {
    "en": {
        message: {
            "notifications": {
                "verificationSuccess": "Your email has been verified. You can now shop and reserve treatments. ",
                "verificationPending": "Your email is still pending verification. Please check your inbox for the verification email. In case you don't get the email, please also check your spam folder or request a resend. ",
                "verificationResend": "Resend verification email",
                "offlineTitle": "You are currently offline",
                "offline": "Information on this site may become stale. Orders and bookings submitted will be processed when a stable connection is established. ",
                "updateAvailableTitle": "Update available",
                "updateAvailable": "A new version of this app is available. Click to update 👉 ",
                "updateApp": "Update",
            },

            "back-to-top-btn": {
               "title": "Back to Top"
            },

            "navbar": {
                "menu": "Menu",
                "basket": "Basket",
                "login-register": "Login/Register",
                "my-account": "Account",
                "sign-out": "Logout",
            },

            "login": {
                "title": "Register / Login",
                "login": "Login",
                "login-sns": "Login with your social accounts: ",
                "login-sns-or": "Or",
                "login-sns-notice-1": "By logging in, you have consented to our ",
                "login-sns-notice-2": "Privacy Policy",
                "login-sns-notice-3": "Terms and Conditions",
                "login-sns-notice-and": " and ",
                "register-line": "Join us now: ",
                "register": "Register",
                "email": "Email",
                "phone": "Phone",
                "region-code": "Region Code",
                "password": "Password",
                "forget-password": "Forget password? ",
                "forget-password-line": "Please enter your email address used at registration. We will send an email containing instruction to reset your password to this address. ",
                "invalid-email": "Please enter a valid email. ",
                "invalid-phone": "Please enter a valid phone. ",
                "invalid-password": "Please enter your password. ",
                "incorrect-login": "Incorrect credentials. Please retry. ",
            },

            "registration": {
                "title": "Registration",
                "form-title": "Title",
                "title-miss": "Miss",
                "title-ms": "Ms.",
                "title-mr": "Mr.",
                "surname": "Surname",
                "name": "Name",
                "email": "Email",
                "country-code": "Country Code",
                "phone": "Phone",
                "password": "Password",
                "show-password": "Show Password",
                "hide-password": "Hide Password",
                "receive-promotion": "Are you willing to receive information and discount from marier Skincare?",
                "receive-promotion-yes": "Yes",
                "receive-promotion-no": "No",
                "how-marier-found": "How did you find us? ",
                "accept-tc-1": "I have read and consented to our ",
                "accept-tc-2": "Privacy Policy",
                "accept-tc-3": "Terms and Conditions",
                "accept-tc-and": " and ",
                "accept-tc-full-stop": ".",
                "register": "Register",
            },

            "forget-password": {
                "title": "Reset Your Password",
                "confirm": "Confirm"
            },

            "account": {
                "title": "My Account",
                "dashboard": "Dashboard",
                "orders": "Orders",
                "bookings": "Bookings",

                "account": "Account",
                "security": "Security",
                "payment-shipping": "Payment and Shipping",
                "message-options": "Messages from marier",

                "name": "Name",
                "phone": "Phone",
                "email": "Email",
                "language": "Language",

                "change-email-notice": "An Account verification email will be delivered to the new email address. You have to verify your Account again. ",
                "change-phone-notice": "We will verify your phone number again. ",

                "password": "Password",
                "change-password": "Change Password",
                "current-password": "Current Password",
                "new-password": "New Password",

                "twoFactorAuth": "Two Factor Authentication",
                "enable-two-factor-auth": "Enable Two Factor Authentication",
                "disable-two-factor-auth": "Disable Two Factor Authentication",

                "one-time-password": "One-time Password",
                "otp-description": "Supports one-time password apps, such as Google Authenticator, Microsoft Authenticator, and Authy. ",
                "otp-verify-description": "Please enter the 6-digit token generated by your one-time password app below for verification: ",
                "verify-token": "Verify Token",
                "scan-enter-otp": "Scan the above QR Code with your preferred one-time password app or enter this code manually: ",
                "disable-otp": "Disable One-Time Password",

                "hw-security-key": "Hardware Security Key",
                "hw-security-key-description": "Supports any hardware security key conforming to the FIDO U2F standard. ",

                "no-data": "Not Available",
                "enable": "Enable",
                "enabled": "Enabled",
                "disable": "Disable",
                "edit": "Edit",
                "done": "Done",
                "verify": "Verify",

                "pay-order": "Pay Order",
                "update-order": "Update Order",
                "cancel-order": "Cancel Order",
            },

            "basket": {
                "products": {
                    "title": "Products",
                    "empty": {
                        "headline": "Your basket is empty",
                        "subheadline": "Make it happy with some of our products!"
                    }
                },
                "treatments": {
                    "title": "Treatments",
                    "empty": {
                        "headline": "Your basket is empty",
                        "subheadline": "Make it happy with some of our treatments!"
                    },
                    "options": "Options: ",
                    "date": "Booking Date: ",
                    "branch": "Booking Branch: "
                },
                "quantity": "Quantity",
                "unit-price": "Price: ",
                "delivery": "Delivery: ",
                "sub-total": "Subtotal",
                "subTotal": "Subtotal: ",
                "total": "Total: ",
                "delete": "Delete",
                "login-verification-required": "Please login or register",
                "promotion": "Promotion: ",
                "have-a-coupon-code": "Have a coupon code? ",
                "coupon-code": "Coupon Code",
                "coupon-code-applied": "Applied coupon code",
                "redeem": "Redeem",
            },

            "checkout": {
                "title": "Checkout",
                "delivery": "Delivery",
                "promotion": "Promotion",
                "sub-total": "Subtotal",
                "total": "Total",
                "my-basket": "My Basket",
                "my-order": "My Order",
                "products": "product(s)",
                "treatments": "treatment(s)",
                "items": "item(s)",
                "contact": "Contact",
                "contact-email-notice": "An order confirmation will be sent to this email address. ",
                "contact-phone-notice": "We may contact you via this phone number.",
                "delivery-address": "Delivery Address",
                "delivery-method": "Delivery Method",
                "delivery-standard": "Standard shipping",
                "payment-method": "Payment Method",
                "first-name": "First name",
                "last-name": "Last name",
                "email": "Email",
                "phone": "Phone",
                "optional": "Optional",
                "country": "Country / Region",
                "postcode": "Postcode",
                "next": "Next",
                "confirm": "Confirm",
                "confirm-order": "Confirm Order",
                "edit": "Edit",
                "add": "Add",
                "address": {
                    "title": "Address",
                    "select": "Select",
                    "selected": "Selected",
                    "delete": "Delete",
                },
                "add-address": "Add a new address",
                "add-another-card": "Use another card",
                "accept-tc-1": "I accept the ",
                "accept-tc-2": "terms and conditions",
                "accept-tc-3": ". ",
                "thank-you": "Thank you!",
                "pay-on-site-notice": "Please choose \"Pay on Site\" should you require installment plan or would like to make cash payment. Online payment only available to credit cards, PayPal, AliPay, and WeChat Pay. ",
                "payment-methods": {
                    "pay-on-site": "Pay on Site",
                    "cards": "Cards",
                    "paypal": "PayPal",
                    "alipay": "AliPay",
                    "wechat-pay": "WeChat Pay",
                },
                "payment-request-api-or-cards": "Or you can choose or add a payment card below",
                "card": {
                    "select": "Select",
                    "selected": "Selected",
                    "delete": "Delete",
                    "last-4-digit": "Last 4 Digits:",
                    "valid-till": "Valid till:",
                    "name": "Cardholder Name",
                },
                "add-card": {
                    "title": "Add a card",
                    "card-name": "Name on card",
                    "card-name-hint": "Full name as displayed on card",
                    "card-number": "Card number",
                    "card-expiry": "Expiry",
                    "card-cvc": "CVC/CVV",
                    "buttons": {
                        "add": "add",
                        "cancel": "cancel",
                    },
                },
                "payment-in-progress": "Payment in Progress...",
                "payment-failed": "Payment Failed. ",
                "retry-payment": "Retry? ",
                "confirm-email-sent": "An order confirmation has been sent to your email address: ",
            },

            "home": {
                "introduction": "Introduction",
                "tag-line-1": "",
                "tag-line-2": "Age-defying Skincare Technology",
                "intro-1": "Leading the way to a better future, marier Skincare is the world's first Stem Cell Therapy brand, proudly founded by a group of skincare connoisseurs. ",
                "intro-2": "With no compromise to the quality and visible result, the technology marier grounded on was a state-of-the-art-science with bio-chemical research over a decade. The result was dedicated to unmet medical needs, which now has proudly extended to a range of intelligent high-performance anti-ageing skincare products. ",
                "intro-3": "marier believes a promising result only lead by the purest and finest world-class potent ingredients.",
                "featured-products": "Featured Products",
                "featured-treatments": "Featured Treatments",
                "know-more": "Shop Now",
            },

            "treatments": {
                "title": "Treatments",
                "options": "Options",
                "select-sku": "Please select your desired treatment(s): ",
                "select-date": "Select Date",
                "datepicker-placeholder": "Click to select a date",
                "select-time": "Select Time",
                "select-branch": "Select Branch",
                "select-branch-region": "Region",
                "duration": "Duration",
                "openHours": "Opening hours: ",
                "hotline": "Hotline: ",
                "price": "Price: ",
                "book-now": "Add to Basket",
            },

            "treatment-faq": {
                "title": "Frequently Asked Questions",
            },

            "products": {
                "title": "Products",
                "add-to-cart": "Add to Basket",
                "added-to-cart": "Added to Basket",
                "share-to": "Share to: ",
                "introduction": "Introduction",
                "usage": "Usage",
                "payment-methods-terms": "Payment Methods",
                "online-shopping-terms": "Online Shopping Terms and Conditions",
                "terms": "Terms and Conditions",
                "delivery-methods-terms": "Order Terms and Delivery Services",
            },

            "promotions": {
                "book-now": "Reserve Now",
            },

            "blog": {
                "title": "Blog",
            },

            "news": {
                "title": "News",
            },

            "article": {
                "read-more": "Read more",
            },

            "about": {
                "title": "About marier",
                "tag-line-lead": "",
                "tag-line": "\"Discover and use all the best things in the world to activate skin rejuvenation with mild natural ingredients and breakthrough technology.\"",
                "paragraph-1": "Founded in 2012, marier Skincare is the world's No. 1 Stem Cell Authoritative Expert. It collects the highest quality raw materials from all over the world. In response to the aging problem of the skin, marier Skincare develops innovative and high-quality anti-aging products with high technology for different type of Asians. It aims to gain its reputation by its products and its beauty services. ",
                "paragraph-2": "marier skincare believes the importance of stem cells to the skin. In collaboration with the top research teams in Korea, marier Skincare has successfully developed SCCM, a high-affinity human bone marrow stem cell culture solution that gained international patents to quickly repair damaged cells and hydrate the skin. ",
                "pharmicell-title": "About Pharmicell",
                "paragraph-3": "Pharmicell is the global leader in stem cell therapy products by launching the world's first stem cell therapy product. They are developing the world first stem cell drug, opens new world as the global leader of next generation bio medicine business. Established in Oct. 2002, cell therapy lab has been dedicated to research for commercialization of stem cell drugs. The areas of Business including Stem Cell therapy products business-Cellgram, Bio chemical, Stem cell banking business-Twelve, Bio-based business and Cosmetics business.",
                "paragraph-4": "The cosmetics business created a stem cell culture media (SCCM) cosmetic brand with advanced technology of Pharmicell. It returns human's youth by human-derived stem cell culture media extract. Cosmetics that transcend time presents beauty by SCCM, cosmetics contain Human bone marrow derived stem cell conditioned media that enclose the skin growth factors and various active ingredients that allow skin to be healthy and shining. Experience the most beautiful skin, you have never experienced before.",
                "paragraph-5": "Its research to prove its stem cell culture media is effective in skin moisturizing and anti-wrinkle care in the world's top journal in skin condition. Pharmicell's stem cell culture media is published in the world renowned journal for its 'outstanding effectiveness' in moisturizing and anti-wrinkle care.",
                "paragraph-6": "Based on our experience, we understand the special needs of Asian woman. Innovation technology support us to develop more healthy products. It leads customers to be more confidence on our quality products. We persist to cooperate with the top team, to develop more quality products which is more suitable for modern ladies. ",
                "paragraph-7": "We expected to build up loving and trustful relationship with customers. Our professional beauty consultant are with sincere attitude to care about your feelings and needs. ",
            },

            "locations": {
                "title": "Store Locator"
            },

            "privacy": {
                "title": "Privacy Policy"
            },

            "terms": {
                "title": "Terms and Conditions"
            },

            "delivery-methods": {
                "title": "Order Terms and Delivery Services",
                "delivery-cat-1": "marierskincare.com provides convenient and prompt delivery service.  Free shipping and delivery areas divided to ",
                "delivery-cat-2": " categories. ",
                "delivery-method": "Delivery Method",
                "purchase-condition-larger-equal": "Purchase less than HK$",
                "purchase-condition-larger-1": "Purchase HK$",
                "purchase-condition-larger-2": " or less",
                "purchase-condition-free-larger-equal-1": "Purchase HK$",
                "purchase-condition-free-larger-equal-2": " or above",
                "purchase-condition-free-larger": "Purchase more than HK$",
                "delivery-time": "Delivery Time (Working Days)",
                "free": "Free",
            },

            "payment-methods": {
                "title": "Payment Methods"
            },

            "online-shopping-terms": {
                "title": "Online Sopping Terms and Conditions"
            },

            "contact-us": {
                "title": "Contact Us",
                "address": "Unit 602, 6th Floor Mira Place Tower A, 132 Nathan Road, Tsimshatsui, Kowloon",
                "form-title": "Inquire now",
                "form-name-title": "Title",
                "form-name": "Name",
                "form-gender": "Gender",
                "form-gender-male": "Male",
                "form-gender-female": "Female",
                "form-email": "Email",
                "form-country-code": "Country Code",
                "form-phone": "Phone",
                "form-content": "Message",
                "form-terms-checkbox": "I hereby agree that I have read and will abide by the privacy policy. ",
                "form-send": "Send",
                "form-complete-title": "Thank you for contacting us!",
                "form-complete": "We have received your message. We will get back to you soon. ",
                "form-error-title": "Houston, we have a problem",
                "form-error": "We are sorry. Please try again later. ",
            },
        }
    }
}
