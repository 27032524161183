<template>
    <section class="home">
        <div class="bg-filter position-fixed"></div>

        <carousel v-bind:banners="banners"></carousel>

        <view-title underline color="white" reveal-animation="fade-down"
                    v-bind:title="$t('message.home.introduction')">
        </view-title>

        <jumbotron class="intro"
                   v-bind:bg-filter-styles="introFilterStyles">
            <h5 class="text-center mb-5 font-weight-normal" data-aos="fade-in">
                <span>{{ $t("message.home.intro-1") }}</span>
            </h5>
            <h5 class="text-center mb-5 font-weight-normal" data-aos="fade-in">
                <span>{{ $t("message.home.intro-2") }}</span>
            </h5>
            <h5 class="text-center font-weight-normal" data-aos="fade-in">
                <span>{{ $t("message.home.intro-3") }}</span>
            </h5>
        </jumbotron>

        <view-title underline color="white" reveal-animation="fade-down"
                    v-bind:title="$t('message.home.featured-products')">
        </view-title>

        <div class="featurettes">
            <div class="container p-0">
                <div class="row no-gutters">
                    <div class="col-md-6 mb-5 mb-md-0 px-2 px-lg-0"
                         v-for="product in featuredProducts"
                         v-bind:key="product._id">
                        <featurette v-bind:direction="product.direction"
                                    v-bind:to="product.to"
                                    v-bind:images="product.images"
                                    v-bind:title="product.title[$i18n.locale]"
                                    v-bind:description="product.description[$i18n.locale]">
                        </featurette>
                    </div>
                </div>
            </div>
        </div>

        <view-title underline color="white" reveal-animation="fade-down"
                    v-bind:title="$t('message.home.featured-treatments')">
        </view-title>

        <div class="featurettes pb-5">
            <div class="container p-0">
                <featurette v-for="treatment in featuredTreatments"
                            v-bind:key="treatment._id"
                            v-bind:direction="treatment.direction"
                            v-bind:to="treatment.to"
                            v-bind:images="treatment.images"
                            v-bind:title="treatment.title[$i18n.locale]"
                            v-bind:description="treatment.description[$i18n.locale]">
                </featurette>
            </div>
        </div>
    </section>
</template>

<script>
    import Jumbotron from "../components/Home/Jumbotron";
    import ViewTitle from "../components/ViewTitle";
    import Featurette from "../components/Featurette";
    import Carousel from "../components/Home/Carousel";

    export default {
        name: "home",
        components: {
            Carousel,
            Featurette,
            ViewTitle,
            Jumbotron,
        },
        metaInfo () {
            return {
                title: `${ this.$t("message.home.tag-line-1") } ${ this.$t("message.home.tag-line-2") }`,
                meta: [{
                    "property": "og:title",
                    "content": `${ this.$t("message.home.tag-line-1") } ${ this.$t("message.home.tag-line-2") }`,
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }]
            }
        },
        data () {
            return {

            }
        },
        computed: {
            banners () {
                return this.$store.state.mall.mall.banners;
            },

            introFilterStyles () {
                return {
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(1px)",
                };
            },

            featuredProducts () {
                return [{
                    "_id": "5b65c9176df87dbf87932cb4",
                    "direction": "top",
                    "to": {
                        "name": "product-series",
                        "params": {
                            "id": "5ba2aabff0677f03e1d69a70"
                        }
                    },
                    "title": {
                        "en": "<img src='./logo_sccm.png' alt='SCCM' style='width: 100%; padding-left: 15px; padding-right: 15px; margin-bottom: -25px;'>",
                        "zh-hk": "<img src='./logo_sccm.png' alt='SCCM' style='width: 100%; padding-left: 15px; padding-right: 15px; margin-bottom: -25px;'>",
                        "zh-cn": "<img src='./logo_sccm.png' alt='SCCM' style='width: 100%; padding-left: 15px; padding-right: 15px; margin-bottom: -25px;'>"
                    },
                    "images": {
                        "image/jp2": "./image_sccm.jp2",
                        "image/webp": "./image_sccm.webp",
                        "default": "./image_sccm.png"
                    },
                    "description": {
                        "en": "<p>Stem Cell Conditioned Media is an extension of the latest and ground breaking medical technology from renowned pharmaceutical lab in Korea - Pharmicell. It is also an exclusive and state-of-art technology to the skincare market, with it's effectiveness approved by FDA. SCCM contains essential skin maintenance factor such as anti-aging factor and anti-scar forming factor. </p>",
                        "zh-hk": "<p>肌膚年齡，由妳一手逆轉。世界權威護膚專家Marier，聯同韓國頂尖科研團隊Pharmicell，攜手研發出珍貴SCCM成份，全面激活細胞，修護肌膚。將最高端科研成果，昇華至極致護膚層次。</p>",
                        "zh-cn": "<p>肌肤年龄，由妳一手逆转。世界权威护肤专家Marier，联同韩国顶尖科研团队Pharmicell，携手研发出珍贵SCCM成份，全面激活细胞，修护肌肤。将最高端科研成果，升华至极致护肤层次。</p>"
                    }
                }, {
                    "_id": "5b65c91aba67f7df334a945f",
                    "direction": "bottom",
                    "to": {
                        "name": "product-series",
                        "params": {
                            "id": "5ba3680e94f36e0e4f15c3f2"
                        }
                    },
                    "title": {
                        "en": "<h1 style='margin-bottom: 25px; font-weight: 500; font-size: 3.5rem; color: #b79a6e; '>Sooyeon Series</h1>",
                        "zh-hk": "<h1 style='margin-bottom: 25px; font-weight: 500; font-size: 3.5rem; color: #b79a6e; '>秀妍金箔系列</h1>",
                        "zh-cn": "<h1 style='margin-bottom: 25px; font-weight: 500; font-size: 3.5rem; color: #b79a6e; '>秀妍金箔系列</h1>"
                    },
                    "images": {
                        "image/jp2": "./image_gold.jp2",
                        "image/webp": "./image_gold.webp",
                        "default": "./image_gold.png"
                    },
                    "description": {
                        "en": "<p>The Sooyeon series formulation grounded on the ancient Dongui Bogam, which consisted with numerous precious and rare plant extract. </p>",
                        "zh-hk": "<p>百年韓方草本醫學經典</p><p>東醫寶鑒編錄古代肌膚調理智慧，講求陰陽五行調和，著重氣血運行；當中記載運用大自然中各種珍貴草本藥材，經長時間熬煮調配成韓方複合成分，達至臓腑相互協調之效。</p>",
                        "zh-cn": "<p>百年韩方草本医学经典</p><p>东医宝鉴编录古代肌肤调理智慧，讲求阴阳五行调和，着重气血运行；当中记载运用大自然中各种珍贵草本药材，经长时间熬煮调配成韩方复合成分，达至臓腑相互协调之效。</p>"
                    }
                }];
            },

            featuredTreatments () {
                return [{
                    "_id": "5b65c96f87970564a28d7acb",
                    "direction": "right",
                    "to": "/campaign/intima",
                    "title": {
                        "en": "<img src='./treatment_title_home.png' alt='Ultra 360 Femme' style='width: 100%; margin-bottom: 20px; '>",
                        "zh-hk": "<img src='./treatment_title_home.png' alt='Ultra 360 Femme' style='width: 100%; margin-bottom: 20px; '>",
                        "zh-cn": "<img src='./treatment_title_home.png' alt='Ultra 360 Femme' style='width: 100%; margin-bottom: 20px; '>",
                    },
                    "images": {
                        "image/jp2": "./treatment_home.jp2",
                        "image/webp": "./treatment_home.webp",
                        "default": "./treatment_home.png"
                    },
                    "description": {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                }];
            }
        }
    }
</script>

<style lang="scss" scoped>
    .home {

        & > .bg-filter {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            background-image: url("../assets/intro_bg.png");
            background-repeat: no-repeat;
            background-size: cover;

            z-index: -1;
        }

        & > .intro {
            min-height: 500px;
            color: rgba(113, 113, 113, 1);
            z-index: 9;

            .clip-wrapper {
                background-color: rgba(255, 255, 255, 0.5)
            }
        }

        & > .featurettes {
            background-color: rgba(255, 255, 255, 1);
        }
    }
</style>
