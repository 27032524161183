<template>
    <section class="view register">
        <view-title v-bind:title="$t('message.registration.title')"></view-title>
        <div class="container py-5">
            <b-form v-on:submit.prevent.stop="register">
                <div class="form-row">
                    <b-form-group class="col-md-2"
                                  v-bind:label="$t('message.registration.form-title')">
                        <b-form-select class="mr-sm-2 rounded-0"
                                       required
                                       v-model="title">
                            <option v-bind:value="'miss'">
                                {{ $t("message.registration.title-miss") }}
                            </option>
                            <option v-bind:value="'ms'">
                                {{ $t("message.registration.title-ms") }}
                            </option>
                            <option v-bind:value="'mr'">
                                {{ $t("message.registration.title-mr") }}
                            </option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group class="col-md-4"
                                  label-for="surname"
                                  v-bind:label="$t('message.registration.surname')"
                                  v-bind:invalid-feedback="invalidFeedback"
                                  v-bind:valid-feedback="validFeedback">
                        <b-form-input id="surname"
                                      class="rounded-0"
                                      required
                                      v-bind:state="state"
                                      v-model.trim="surname">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-6"
                                  label-for="name"
                                  v-bind:label="$t('message.registration.name')"
                                  v-bind:invalid-feedback="invalidFeedback"
                                  v-bind:valid-feedback="validFeedback">
                        <b-form-input id="name"
                                      class="rounded-0"
                                      required
                                      v-bind:state="state"
                                      v-model.trim="name">
                        </b-form-input>
                    </b-form-group>
                </div>

                <div class="form-row">
                    <b-form-group class="col-md-6"
                                  label-for="email"
                                  v-bind:label="$t('message.registration.email')"
                                  v-bind:invalid-feedback="invalidFeedback"
                                  v-bind:valid-feedback="validFeedback">
                        <b-form-input id="email"
                                      class="rounded-0"
                                      type="email"
                                      required
                                      v-bind:state="state"
                                      v-model.trim="email">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-2"
                                  v-bind:label="$t('message.registration.country-code')">
                        <b-form-select class="mr-sm-2 rounded-0"
                                       v-model="countryCode">
                            <option v-bind:value="'+852'">+852</option>
                            <option v-bind:value="'+853'">+853</option>
                            <option v-bind:value="'+86'">+86</option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group class="col-md-4"
                                  label-for="phone"
                                  v-bind:label="$t('message.registration.phone')"
                                  v-bind:invalid-feedback="invalidFeedback"
                                  v-bind:valid-feedback="validFeedback">
                        <b-form-input id="phone"
                                      class="rounded-0"
                                      type="tel"
                                      pattern="[0-9\-]+"
                                      required
                                      v-bind:state="state"
                                      v-model.trim="phone">
                        </b-form-input>
                    </b-form-group>
                </div>

                <div class="form-row">
                    <b-form-group class="col-md-12"
                                  label-for="password"
                                  v-bind:label="$t('message.registration.password')"
                                  v-bind:invalid-feedback="invalidFeedback"
                                  v-bind:valid-feedback="validFeedback">
                        <button type="button" class="btn btn-link position-absolute reveal-password"
                                v-on:click="showPassword = !showPassword">
                        <span v-if="!showPassword">
                            <font-awesome-icon v-bind:icon="['far', 'eye']"></font-awesome-icon> {{ $t("message.registration.show-password") }}
                        </span>
                            <span v-if="showPassword">
                            <font-awesome-icon v-bind:icon="['far', 'eye-slash']"></font-awesome-icon> {{ $t("message.registration.hide-password") }}
                        </span>
                        </button>
                        <b-form-input id="password"
                                      class="rounded-0"
                                      required
                                      v-bind:type="showPassword ? 'text' : 'password'"
                                      v-model.trim="password">
                        </b-form-input>
                    </b-form-group>
                </div>

                <b-form-group v-bind:label="$t('message.registration.receive-promotion')">
                    <b-form-radio-group stacked
                                        required
                                        v-model="acceptContact">
                        <b-form-radio value="true">
                            {{ $t("message.registration.receive-promotion-yes") }}
                        </b-form-radio>
                        <b-form-radio value="false">
                            {{ $t("message.registration.receive-promotion-no") }}
                        </b-form-radio>
                    </b-form-radio-group>
                </b-form-group>

                <div class="form-row">
                    <b-form-group class="col-md-6"
                                  v-bind:label="$t('message.registration.how-marier-found')">
                        <b-form-select required
                                       v-model="source">
                            <option value="google">Google</option>
                            <option value="yahoo">Yahoo!</option>
                            <option value="facebook">Facebook</option>
                            <option value="baidu">百度</option>
                            <option value="weibo">微博</option>
                            <option value="friend">朋友推薦</option>
                            <option value="forum">論壇</option>
                            <option value="email">推廣電郵</option>
                            <option value="online_adv">網上廣告</option>
                            <option value="magazine">雜誌廣告</option>
                            <option value="tv">電視廣告</option>
                            <option value="shop">marier Skincare曼肌兒店舖</option>
                            <option value="others">其它</option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <b-form-group>
                    <b-form-checkbox value="true"
                                     unchecked-value="false"
                                     required
                                     v-model="acceptTC">
                        {{ $t("message.registration.accept-tc-1") }}<a href="/privacy" target="_blank">{{ $t("message.registration.accept-tc-2") }}</a>{{ $t("message.registration.accept-tc-and") }}<a href="/terms" target="_blank">{{ $t("message.registration.accept-tc-3") }}</a>{{ $t("message.registration.accept-tc-full-stop") }}
                    </b-form-checkbox>
                </b-form-group>

                <div class="d-flex justify-content-center">
                    <button class="btn btn-outline-dark rounded-0 px-5"
                            v-bind:class="{ 'btn-loading': userStatus === 'registering' }">
                        {{ $t("message.registration.register") }}
                    </button>
                </div>
            </b-form>
        </div>
    </section>
</template>

<script>
    import {
        BForm,
        BFormInput,
        BFormRadioGroup,
        BFormRadio,
        BFormCheckbox,
        BFormSelect,
        BFormGroup,
    } from "bootstrap-vue";

    import ViewTitle from "../components/ViewTitle";
    export default {
        name: "Register",
        components: {
            ViewTitle,
            BForm,
            BFormInput,
            BFormRadioGroup,
            BFormRadio,
            BFormCheckbox,
            BFormSelect,
            BFormGroup,
        },
        metaInfo () {
            return {
                title: this.$t("message.registration.title"),
                meta: [{
                    "name": "description",
                    "content": this.$t("message.registration.title"),
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.$t("message.registration.title"),
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": this.$t("message.registration.title"),
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "website",
                    "vmid": "og:type"
                }]
            }
        },
        data () {
            return {
                titleList: [{
                    "value": "miss",
                    "text": {
                        "zh-hk": "",
                        "zh-cn": "",
                        "en": ""
                    }
                }, {
                    "value": "ms",
                    "text": {
                        "zh-hk": "",
                        "zh-cn": "",
                        "en": ""
                    }
                }, {
                    "value": "mr",
                    "text": {
                        "zh-hk": "",
                        "zh-cn": "",
                        "en": ""
                    }
                }],
                countryCodeList: [],
                countryCode: "+852",
                showPassword: false,

                title: null,
                name: "",
                surname: "",
                phone: "",
                password: "",
                acceptContact: null,
                source: null,
                acceptTC: null,
            };
        },
        computed: {
            invalidFeedback () {
                if (this.name.length > 4) {
                    return '';
                }
                if (this.name.length > 0) {
                    return "Enter at least 4 characters";
                }
                return "Please enter something";
            },

            validFeedback () {
                return this.state === true ? 'Thank you' : ''
            },

            userStatus () {
                return this.$store.state.user.status;
            }
        },
        methods: {
            async register () {
                console.log(this.title);
                try {
                    await this.$store
                        .dispatch(
                            "user/register",
                            {
                                "title": this.title,
                                "surname": this.surname,
                                "name": this.name,
                                "countryCode": this.countryCode,
                                "phone": this.phone,
                                "email": this.email,
                                "password": this.password,
                                "profile": {
                                    acceptContact: this.acceptContact,
                                    acceptTC: this.acceptTC,
                                    source: this.source,
                                    lang: this.$i18n.locale
                                }
                            }
                        );
                } catch (e) {
                    console.log(e);
                    return;
                }

                let target = {
                    "name": "home"
                };
                if (this.$route.query.to) {
                    target = {
                        path: this.$route.query.to
                    };
                }
                this.$router.push(target);
            }
        }
    }
</script>

<style lang="scss">
    .register {
        color: rgba(113, 113, 113, 1);

        .col-form-label {
            font-weight: bold;
        }

        .reveal-password {
            top: 0;
            right: 0;

            &:hover {
                text-decoration: none;
            }
        }
    }
</style>
