<template>
    <section class="view product-details">
        <div class="banner position-relative pb-4">
            <background-filter v-if="!product.videoCover"
                               v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ product.cover }`">
            </background-filter>
            <background-filter v-else
                               v-bind:is-video="product.videoCover"
                               v-bind:provider="product.videoCover.provider"
                               v-bind:src="product.videoCover.src"
                               v-bind:poster="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ product.cover }`">
            </background-filter>
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-5 pr-md-4 pb-4 pb-md-0"
                         data-aos="fade-up"
                         data-aos-duration="800"
                         data-aos-delay="300">
                        <div class="product-image position-relative text-center">
                            <div class="main"
                                 v-on:click="showImageModal = true">
                                <img class="img-responsive"
                                     alt="Product Image"
                                     v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ product.images[0] }`">
                                <div class="expand">
                                    <font-awesome-icon icon="expand-alt"></font-awesome-icon>
                                </div>
                            </div>
                            <img class="mirror position-absolute"
                                 alt="Product Image Mirrorred"
                                 v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ product.images[0] }`">
                        </div>
                    </div>
                    <div class="col-md-7 d-flex flex-column justify-content-center"
                         data-aos="fade-in"
                         data-aos-duration="1000"
                         data-aos-delay="400">
                        <h1 class="text-center text-md-left font-weight-light mb-0"
                            v-bind:style="{ 'color': product.titleColor ? `rgba(${ product.titleColor.r }, ${ product.titleColor.g }, ${ product.titleColor.b }, ${ product.titleColor.a }) !important` : '' }"
                            v-html="product.title[$i18n.locale]">
                        </h1>

                        <p class="text-center text-md-left lead"
                           v-bind:style="{ 'color': product.titleColor ? `rgba(${ product.titleColor.r }, ${ product.titleColor.g }, ${ product.titleColor.b }, ${ product.titleColor.a }) !important` : '' }"
                           v-html="product.subtitle[$i18n.locale]">
                        </p>

                        <h2 class="text-center text-md-left mb-4"
                            v-bind:style="{ 'color': product.titleColor ? `rgba(${ product.titleColor.r }, ${ product.titleColor.g }, ${ product.titleColor.b }, ${ product.titleColor.a }) !important` : '' }">
                            <small>HK$</small>{{ Array.isArray(product.prices) ? product.prices[0].hkd : 0 | currency("", 0) }}
                        </h2>

                        <p class="text-center text-md-left">
                            <button class="btn btn-lg btn-dark rounded-0"
                                    v-bind:style="{ 'color': product.buttonColors && product.buttonColors.text ? `rgba(${ product.buttonColors.text.r }, ${ product.buttonColors.text.g }, ${ product.buttonColors.text.b }, ${ product.buttonColors.text.a }) !important` : '', 'background-color': product.buttonColors && product.buttonColors.background ? `rgba(${ product.buttonColors.background.r }, ${ product.buttonColors.background.g }, ${ product.buttonColors.background.b }, ${ product.buttonColors.background.a }) !important` : '' }"
                                    v-on:click="addToCart">
                                {{ $t("message.products.add-to-cart") }}
                            </button>
                        </p>

                        <div class="d-block d-sm-none text-center"
                             v-bind:style="{ 'color': product.shareColor ? `rgba(${ product.shareColor.r }, ${ product.shareColor.g }, ${ product.shareColor.b }, ${ product.shareColor.a }) !important` : '' }">
                            {{ $t("message.products.share-to") }}
                        </div>

                        <div class="d-flex align-items-baseline justify-content-center justify-content-md-start">
                            <div class="d-none d-sm-inline mr-sm-2"
                                 v-bind:style="{ 'color': product.shareColor ? `rgba(${ product.shareColor.r }, ${ product.shareColor.g }, ${ product.shareColor.b }, ${ product.shareColor.a }) !important` : '' }">
                                {{ $t("message.products.share-to") }}
                            </div>

                            <share-to-s-n-s v-bind:networks="$config.SupportedSNS"
                                            v-bind:style="{ 'color': product.shareColor ? `rgba(${ product.shareColor.r }, ${ product.shareColor.g }, ${ product.shareColor.b }, ${ product.shareColor.a }) !important` : '' }"
                                            v-bind:url="`${ $config.baseUrl }${ $route.path }`"
                                            v-bind:title="`${ product.title[$i18n.locale] } (${ product.subtitle[$i18n.locale] }) | marier Skincare 曼肌兒`"
                                            v-bind:description="product.description[$i18n.locale]"
                                            v-bind:quote="product.title[$i18n.locale]"
                                            v-bind:hashtags="`marier,skincare,${ product.title[$i18n.locale] }`">
                            </share-to-s-n-s>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <page-info-bar v-bind:series="series"
                       v-bind:product="product">
        </page-info-bar>

        <div class="description py-5 mb-4">
            <div class="container">
                <div class="mb-5"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <h2 class="text-center text-md-left">
                        {{ $t("message.products.introduction") }}
                    </h2>
                    <p class="ql-editor p-0"
                       v-html="product.description[$i18n.locale]">
                    </p>
                </div>
                <div data-aos="fade-up"
                     data-aos-duration="1000">
                    <h2 class="text-center text-md-left">
                        {{ $t("message.products.usage") }}
                    </h2>
                    <p class="ql-editor p-0"
                       v-html="product.usage[$i18n.locale]">
                    </p>
                </div>
            </div>
        </div>
        <p class="text-center term-links pb-5">
            <router-link to="/payment-methods">
                {{ $t("message.products.payment-methods-terms") }}
            </router-link> |
            <router-link to="/online-shopping-terms">
                {{ $t("message.products.online-shopping-terms") }}
            </router-link> |
            <router-link to="/terms">
                {{ $t("message.products.terms") }}
            </router-link> |
            <router-link to="/delivery-methods">
                {{ $t("message.products.delivery-methods-terms") }}
            </router-link>
        </p>

        <b-modal hide-header hide-footer centered
                 size="lg"
                 body-class="p-0 image-modal"
                 v-model="showImageModal"
                 v-on:cancel="showImageModal = false">
            <div class="d-flex justify-content-end align-items-center">
                <button class="btn btn-lg btn-outline-light border-0"
                        style="color: rgba(113, 113, 113, 1);"
                        v-on:click="showImageModal = false">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
            <img class="img-responsive d-block mx-auto"
                 alt="Preview"
                 v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ product.images[0] }`">
        </b-modal>
    </section>
</template>

<script>
import {
    BModal,
} from "bootstrap-vue";

import BackgroundFilter from "../components/BackgroundFilter";
import PageInfoBar from "../components/PageInfoBar";
import ShareToSNS from "../components/ShareToSNS";
export default {
    name: "ProductDetails",
    components: {
        ShareToSNS,
        PageInfoBar,
        BackgroundFilter,
        BModal,
    },
    metaInfo () {
        return {
            title: this.$sanitize(`${ this.product.title[this.$i18n.locale] } (${ this.product.subtitle[this.$i18n.locale] })`),
            meta: [{
                "name": "description",
                "content": this.$sanitize(this.product.description[this.$i18n.locale]),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.$sanitize(`${ this.product.title[this.$i18n.locale] } (${ this.product.subtitle[this.$i18n.locale] })`),
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$sanitize(this.product.description[this.$i18n.locale]),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "product.item",
                "vmid": "og:type"
            }, {
                "property": "og:image",
                "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                "vmid": "og:image"
            }]
        }
    },
    data () {
        return {
            isLoading: false,
            showImageModal: false,
        }
    },
    computed: {
        product () {
            let target = this.$store.state.mall.shop.products.find(product => product._id === this.$route.params.id);
            console.log(target);
            if (target) {
                return target;
            }
            return {
                title: {
                    "en": "...",
                    "zh-hk": "...",
                    "zh-cn": "..."
                },
                subtitle: {
                    "en": "...",
                    "zh-hk": "...",
                    "zh-cn": "..."
                },
                description: {
                    "en": "...",
                    "zh-hk": "...",
                    "zh-cn": "..."
                },
                usage: {
                    "en": "...",
                    "zh-hk": "...",
                    "zh-cn": "..."
                },
                images: [],
                cover: "",
            }
        },

        series () {
            let target = this.$store.state.mall.shop.series.find(series => series._id === this.product.series);
            if (target) {
                return target;
            }
            return {};
        },
    },
    methods: {
        stuck () {
            console.log("stuck");
            this.infoBarStuck = true;
        },

        unstuck () {
            console.log("unstuck");
            this.infoBarStuck = false;
        },

        addToCart () {
            this.$store.commit(
                "cart/setItemQuantity",
                {
                    "type": "products",
                    "item": this.product,
                    "quantity": 1
                }
            );
        },
    },
    mounted () {

    },
    watch: {
        "$route" (to, from) {

        }
    }
}
</script>

<style lang="scss" scoped>
.product-details {
    .banner {
        min-height: 40vh;

        .img-responsive {
            height: auto;
            max-height: 400px;
            max-width: 100%;
        }

        .product-image {
            cursor: pointer;

            & > .main {
                position: relative;

                & > .expand {
                    visibility: hidden;
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    height: 32px;
                    width: 32px;
                    background-color: rgba(255, 255, 255, 0.75);
                }

                &:hover {
                    & > .expand {
                        visibility: visible;
                    }
                }
            }

            & > .mirror {
                top: 100%;
                left: 0;
                right: 0;

                height: 100%;
                width: inherit;
                margin: 0 auto;

                transform: scaleY(-1);
                mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 1) 100%);
                -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 1) 100%);
            }
        }
    }

    .term-links {
        a {
            color: rgba(113, 113, 113, 1);

            &:hover {
                color: rgba(82, 82, 82, 1);
            }
        }
    }
}

.image-modal {
    .img-responsive {
        max-height: 600px;
        max-width: 100%;
    }
}

@media print {
    .product-image {
        & > .mirror {
            display: none;
        }
    }
}
</style>
