<template>
    <div class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex justify-content-between">
            <div class="flex-3">
                <strong>{{ $t("message.checkout.total") }}</strong>
            </div>
            <div class="flex-1">
                <h5 class="text-right font-weight-bolder">
                    <small>HK$</small>{{ total | currency("", 2) }}
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyBasketTotal",
    props: {
        total: {
            type: [String, Number],
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
