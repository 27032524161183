<template>
    <section class="view cart">
        <view-title reveal-animation="fade-down"
                    v-bind:title="$t('message.navbar.basket')">
        </view-title>

        <div class="container py-5">
            <h2>
                {{ $t("message.basket.products.title") }}
            </h2>

            <basket-list v-bind:cart="productCart"></basket-list>

            <basket-total v-bind:coupon="productCoupons"
                          v-bind:cart="productCart">
            </basket-total>
            <basket-coupon v-model="productCoupons"></basket-coupon>
            <basket-checkout v-bind:coupon="productCoupons"
                             v-bind:cart="productCart">
            </basket-checkout>
        </div>

        <div class="container py-5">
            <h2>
                {{ $t("message.basket.treatments.title") }}
            </h2>

            <basket-list is-treatment
                         v-bind:cart="treatmentCart">
            </basket-list>

            <basket-total is-treatment
                          v-bind:coupon="treatmentCoupons"
                          v-bind:cart="treatmentCart">
            </basket-total>
            <basket-coupon is-treatment
                           v-model="treatmentCoupons">
            </basket-coupon>
            <basket-checkout is-treatment
                             v-bind:coupon="treatmentCoupons"
                             v-bind:cart="treatmentCart">
            </basket-checkout>
        </div>
    </section>
</template>

<script>
import ViewTitle from "../components/ViewTitle";
import BasketTotal from "../components/Basket/BasketTotal";
import BasketCoupon from "../components/Basket/BasketCoupon";
import BasketCheckout from "../components/Basket/BasketCheckout";
import BasketList from "../components/Basket/BasketList";

export default {
    name: "Cart",
    components: {
        BasketList,
        ViewTitle,
        BasketCheckout,
        BasketCoupon,
        BasketTotal,
    },
    metaInfo () {
        return {
            title: this.$t("message.navbar.basket"),
            meta: [{
                "name": "description",
                "content": this.$t("message.navbar.basket"),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.$t("message.navbar.basket"),
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$t("message.navbar.basket"),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "website",
                "vmid": "og:type"
            }]
        }
    },
    data () {
        return {

        };
    },
    computed: {
        productCart () {
            return this.$store.state.cart.cart.products;
        },
        treatmentCart () {
            return this.$store.state.cart.cart.treatments;
        },
        coupons () {
            return this.$store.state.cart.coupon;
        },
        productCoupons: {
            get () {
                console.log(this.coupons["products"]?.[0] ?? null)
                return this.coupons["products"]?.[0] ?? null;
            },
            set (val) {
                this.$store.commit(
                    "cart/setCoupon",
                    {
                        type: "products",
                        coupons: [val],
                    }
                );
            },
        },
        treatmentCoupons: {
            get () {
                return this.coupons["treatments"]?.[0] ?? null;
            },
            set (val) {
                this.$store.commit(
                    "cart/setCoupon",
                    {
                        type: "treatments",
                        coupons: [val],
                    }
                );
            },
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.cart {
    color: rgba(113, 113, 113, 1);
}
</style>
