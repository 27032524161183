<template>
    <div class="list-group-item list-group-item-action list-group-item-secondary flex-column align-items-start">
        <div class="d-flex justify-content-between">
            <h5 class="font-weight-bolder mb-1">
                {{ isOrder ? $t("message.checkout.my-order") : $t("message.checkout.my-basket") }}
            </h5>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-baseline mb-0">
            <p class="mb-0">
                {{ items.length }}
                <span v-if="isTreatment">
                    {{ $t('message.checkout.treatments') }}
                </span>
                <span v-else>
                    {{ $t('message.checkout.products') }}
                </span>,
                {{ cartItemCount }} {{ $t('message.checkout.items') }}
            </p>
            <h4 class="mb-0">
                <small>HK$</small>{{ cartTotal | currency("", 2) }}
            </h4>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyBasketHeader",
    props: {
        isOrder: {
            type: Boolean,
            default: false,
        },
        isTreatment: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        cartItemCount () {
            return this.items.reduce(
                (acc, product) => acc += product.quantity, 0
            );
        },
        cartTotal () {
            return this.items.reduce(
                (acc, product) => acc += product.prices[0].hkd * product.quantity, 0
            );
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
