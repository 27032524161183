import { apiUrl, apiNewVersion } from "../../config";

import localforage from "localforage";

export async function getCouponByCode (
    code
) {
    const token = await localforage.getItem("marier.user-token");

    const response = await fetch(
        `${ apiUrl }/${ apiNewVersion }/coupon/${ code }`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            }
        }
    );

    if (response.ok) {
        const coupon = await response.json();
        if (coupon) {
            return coupon;
        }
        throw "COUPON GET BY CODE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getCouponByCode;
