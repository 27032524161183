<template>
    <section class="view treatment-details">
        <treatment-banner v-bind:treatment="treatment"></treatment-banner>

        <div class="description pt-5 mb-4"
             v-if="descriptions.length > 0">
            <div class="container">
                <div class="mb-5"
                     v-for="(description, index) in descriptions"
                     v-bind:key="index"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <h2 class="text-center text-md-left"
                        v-html="description ? description.title[$i18n.locale] : ''">
                    </h2>
                    <p v-html="description ? description.content[$i18n.locale] : ''"></p>
                </div>
            </div>
        </div>

        <div class="form pb-5 my-4">
            <div class="container">
                <h2 class="text-center text-md-left mb-3">
                    {{ $t("message.treatments.options") }}
                </h2>
                <label>
                    {{ $t("message.treatments.select-sku") }}
                </label>
                <div class="mb-3">
                    <div class="row skus mb-3">
                        <div class="col-md-4 mb-3 sku"
                             v-for="sku in prices"
                             v-bind:key="sku._id">
                            <button type="button"
                                    class="btn btn-block btn-outline-dark font-weight-bold h-100"
                                    v-bind:class="{ 'active': selectedSKUList.find(({ _id }) => _id === sku._id) }"
                                    v-on:click="toggleSKU(sku)">
                                <span>
                                    {{ sku.name[$i18n.locale] }}
                                </span><br>
                                <span>
                                    {{ $t("message.treatments.duration") }}: {{ sku.duration.HH }}:{{ sku.duration.mm }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3 datepicker">
                        <label>
                            {{ $t("message.treatments.select-date") }}
                        </label>
                        <datepicker name="uniquename"
                                    input-class="form-control"
                                    v-bind:language="lang[$i18n.locale]"
                                    v-bind:disabled-dates="disabledDates"
                                    v-bind:placeholder="$t('message.treatments.datepicker-placeholder')"
                                    v-model="date">
                        </datepicker>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label>
                            {{ $t("message.treatments.select-time") }}
                        </label>
                        <b-form-select class="d-block w-100"
                                       v-model="session">
                            <option v-bind:value="null"></option>
                            <option>10:00-11:00</option>
                            <option>11:00-12:00</option>
                            <option>12:00-13:00</option>
                            <option>13:00-14:00</option>
                            <option>14:00-15:00</option>
                            <option>15:00-16:00</option>
                            <option>17:00-18:00</option>
                            <option>18:00-19:00</option>
                            <option>19:00-20:00</option>
                        </b-form-select>
                        <div class="invalid-feedback">
                            Please select a valid time session.
                        </div>
                    </div>
                </div>

                <div class="row no-gutters">
                    <label>
                        {{ $t("message.treatments.select-branch") }}
                    </label>
                </div>
                <div class="row mb-5">
                    <div class="col-md-4 pb-3"
                         v-for="branch in availableBranches"
                         v-bind:key="branch._id">
                        <treatment-branch-option v-bind:branch="branch"
                                                 v-bind:selected="selectedBranch && selectedBranch._id === branch._id"
                                                 v-on:click="selectBranch">
                        </treatment-branch-option>
                    </div>
                </div>

                <div class="row no-gutters my-4">
                    <h3 class="col-md-6">
                        {{ $t("message.treatments.price") }}<small>HK$</small>{{ treatmentTotal | currency("", 0) }}
                    </h3>
                </div>

                <div class="row no-gutters">
                    <button class="btn btn-outline-dark"
                            style="min-width: 250px;"
                            v-bind:class="{ 'btn-loading': loading }"
                            v-bind:disabled="!allowAddToCart"
                            v-on:click="addToCart">
                        {{ $t("message.treatments.book-now") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
    BFormSelect,
} from "bootstrap-vue";

/*import {
    addDays,
    addMonths
} from "date-fns";*/
import Datepicker from "vuejs-datepicker";
import { en, zh } from "vuejs-datepicker/dist/locale"

import BackgroundFilter from "../components/BackgroundFilter";
import TreatmentBanner from "../components/TreatmentDetails/TreatmentBanner";
import TreatmentBranchOption from "../components/TreatmentDetails/TreatmentBranchOption";
export default {
    name: "TreatmentDetails",
    components: {
        TreatmentBranchOption,
        TreatmentBanner,
        Datepicker,
        BackgroundFilter,
        BFormSelect,
    },
    metaInfo () {
        return {
            title: this.treatment ? `${ this.$sanitize(this.treatment.title[this.$i18n.locale]) }` : "",
            meta: [{
                "name": "description",
                "content": this.treatment ? this.$sanitize(this.treatment.subtitle[this.$i18n.locale]) : "",
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.treatment ? `${ this.$sanitize(this.treatment.title[this.$i18n.locale]) }` : "",
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.treatment ? this.$sanitize(this.treatment.subtitle[this.$i18n.locale]) : "",
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "product.item",
                "vmid": "og:type"
            }, {
                "property": "og:image",
                "content": this.treatment ? `${ this.$config.apiUrl }/${ this.$config.apiVersion }/salon/treatment/image/${ this.treatment.images[0] }` : "",
                "vmid": "og:image"
            }]
        }
    },
    props: {
        id: {
            type: String,
        },
    },
    data () {
        return {
            loading: false,

            lang: {
                "en": en,
                "zh-hk": zh,
                "zh-cn": zh,
            },

            /*disabledDates: {
                to: addDays(new Date(), 1),
                from: addMonths(new Date(), 6)
            },*/

            selectedSKUList: [],
            selectedBranch: null,
            date: null,
            session: null,
        }
    },
    computed: {
        treatment () {
            const target = this.$store.state.mall.salon.treatments.find(
                treatment => treatment._id === this.id
            );
            if (target) {
                target.availableBranches.forEach(
                    branch => {
                        branch.coordinate.lat = parseFloat(branch.coordinate.lat);
                        branch.coordinate.lng = parseFloat(branch.coordinate.lng);
                    }
                );
                return target;
            }
            return null;
        },
        descriptions () {
            return this.treatment?.descriptions ?? [];
        },
        prices () {
            return this.treatment?.prices ?? [];
        },
        availableBranches () {
            return this.treatment?.availableBranches ?? [];
        },
        disabledDates () {
            const defaultDates = [
                new Date(2020, 7, 12),
                new Date(2020, 7, 13),
                new Date(2020, 7, 14),
                new Date(2020, 7, 15),
                new Date(2020, 7, 16),
                new Date(2020, 7, 17),
                new Date(2020, 7, 18),
            ];
            const dates = {
                to: new Date(),
                dates: [...defaultDates],
                ranges: [],
            };

            if (this.selectedBranch) {
                const branchRange = {};
                if (this.selectedBranch.openingDate) {
                    branchRange.to = (new Date(this.selectedBranch.openingDate).getTime() > new Date().getTime()) ?
                        new Date(this.selectedBranch.openingDate) :
                        dates.to;
                }

                if (this.selectedBranch.closingDate) {
                    branchRange.from = new Date(this.selectedBranch.closingDate);
                }

                if (Object.keys(branchRange).length > 0) {
                    dates.ranges.push(branchRange);
                }
            }
            return dates;
        },
        treatmentTotal () {
            return this.selectedSKUList.reduce(
                (acc, sku) => acc += sku.hkd, 0
            );
        },
        allowAddToCart () {
            return this.selectedSKUList.length > 0 && this.selectedBranch && this.date && this.session;
        },
    },
    methods: {
        toggleSKU (sku) {
            if (this.selectedSKUList.find(selectedSku => selectedSku._id === sku._id)) {
                this.selectedSKUList.splice(this.selectedSKUList.findIndex(selectedSku => selectedSku._id === sku._id), 1);
            } else {
                this.selectedSKUList.push(sku);
            }
        },
        selectBranch (branch) {
            console.log(branch);
            this.selectedBranch = branch;
        },
        addToCart () {
            this.$store.commit(
                "cart/setItemQuantity",
                {
                    "type": "treatments",
                    "item": Object.assign(
                        {
                            "skus": this.selectedSKUList,
                            "date": this.date,
                            "session": this.session,
                            "branch": this.selectedBranch
                        },
                        this.treatment
                    ),
                    "quantity": 1
                }
            );
        }
    }
}
</script>

<style lang="scss" scoped>
.treatment-details {
    .datepicker .form-control[readonly] {
        background-color: rgba(255, 255, 255, 1) !important;
    }

    .btn-block {
        white-space: normal;
    }
}
</style>
