<template>
    <article class="featurette h-100"
             v-bind:class="direction">
        <div class="wrapper text-center h-100 d-flex justify-content-between align-items-center">
            <picture class="featurette-image-wrapper">
                <source v-for="(value, mime) in sources"
                        v-bind:key="value"
                        v-bind:type="mime"
                        v-bind:srcset="value">
                <img class="featurette-image"
                     v-bind:data-aos="direction === 'top' ? 'fade-down' : 'fade-up'"
                     data-aos-duration="600"
                     data-aos-delay="200"
                     v-bind:alt="title"
                     v-bind:src="images.default">
            </picture>
            <div class="info d-flex flex-column justify-content-around align-items-center"
                 data-aos="fade-in"
                 data-aos-duration="600"
                 data-aos-delay="400">
                <div class="info-wrapper">
                    <div class="title text-center w-100 mx-auto"
                         v-html="title">
                    </div>
                    <div class="description text-center"
                         v-html="description">
                    </div>
                    <router-link class="btn btn-lg btn-outline-dark rounded-0"
                                 v-bind:to="to">
                        {{ $t("message.home.know-more") }}
                    </router-link>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
    export default {
        name: "Featurette",
        props: {
            direction: {
                type: String,
                default: "top",
            },

            to: {
                type: [String, Object],
                required: true,
            },

            images: {
                type: Object,
            },

            title: {
                type: String,
                required: true
            },

            description: {
                type: String,
                required: true
            },
        },
        data () {
            return {

            }
        },
        computed: {
            sources () {
                const temp = Object.assign({}, this.images);
                delete temp.default;
                return temp;
            },
        }
    }
</script>

<style lang="scss" scoped>
    @mixin lg {
        @media (max-width: 991px) {
            @content;
        }
    }

    @mixin md {
        @media (max-width: 767px) {
            @content;
        }
    }

    @mixin sm {
        @media (max-width: 575px) {
            @content;
        }
    }

    article.featurette {
        padding: 0 60px;
        color: rgba(113, 113, 113, 1);

        @include lg {
            padding: 0;
        }

        @include md {
            padding: 0;
        }

        .wrapper {
            .info {
                flex: 1;
                border: solid 5px rgba(185, 182, 180, 1);

                & > .wrapper {
                    & > .title {

                    }

                    & > .description {

                    }

                    a.btn {
                        border-radius: 1px;
                        border-width: 2px;
                    }
                }
            }

            .featurette-image {
                width: 100%;
                height: auto;
            }
        }

        &.top {
            .wrapper {
                flex-direction: column;

                .featurette-image-wrapper {
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: -50px;

                    padding-top: 15px;
                    padding-left: 35px;
                    padding-right: 35px;

                    width: 100%;
                    height: auto;
                    order: 0;

                    z-index: 1;
                }

                .info {
                    padding: 65px 25px 25px 15px;
                    order: 1;
                    background-color: rgba(255, 255, 255, 1);

                    @include md {
                        border: none;
                    }

                    & > .info-wrapper {

                    }
                }
            }
        }

        &.bottom {
            .wrapper {
                flex-direction: column;

                .featurette-image-wrapper {
                    margin-top: -50px;
                    margin-left: auto;
                    margin-right: auto;

                    padding-bottom: 15px;
                    padding-left: 35px;
                    padding-right: 35px;

                    width: 100%;
                    height: auto;
                    order: 1;

                    z-index: 1;

                    @include md {
                        margin-top: 0;
                        margin-bottom: -50px;
                        order: 0;
                    }
                }

                .info {
                    padding: 25px 25px 65px 15px;
                    order: 0;
                    background-color: rgba(255, 255, 255, 1);

                    @include md {
                        border: none;
                        padding: 65px 25px 25px 15px;
                        order: 1;
                    }

                    & > .info-wrapper {

                    }
                }
            }
        }

        &.right {
            .wrapper {
                flex-direction: row;

                @include md {
                    flex-direction: column;
                }

                .featurette-image-wrapper {
                    width: 50%;

                    margin-left: -50px;

                    padding-top: 25px;
                    padding-bottom: 25px;

                    order: 1;

                    @include md {
                        width: 100%;

                        margin-left: 0;
                        margin-bottom: -15px;

                        padding-left: 25px;
                        padding-right: 25px;
                        padding-bottom: 0;

                        order: 0;

                        z-index: 1;
                    }
                }

                .info {
                    order: 0;
                    z-index: 1;
                    border: none;

                    @include md {
                        order: 1;
                        z-index: 0;
                    }

                    & > .info-wrapper {
                        background-color: rgba(255, 255, 255, 1);
                        border: solid 5px rgba(185, 182, 180, 1);
                        padding: 25px;

                        @include md {
                            padding: 15px;
                        }

                        & > .description {
                            display: none;
                        }
                    }
                }
            }
        }

        &.left {
            .wrapper {
                flex-direction: row;

                .featurette-image-wrapper {
                    width: 50%;

                    margin-right: -50px;

                    padding-top: 25px;
                    padding-bottom: 25px;

                    order: 0;

                    @include md {
                        width: 100%;

                        margin-right: 0;
                        margin-bottom: -15px;

                        padding-left: 25px;
                        padding-right: 25px;
                        padding-bottom: 0;

                        z-index: 1;
                    }
                }

                .info {
                    order: 1;
                    z-index: 1;
                    border: none;

                    @include md {
                        z-index: 0;
                    }

                    & > .info-wrapper {
                        background-color: rgba(255, 255, 255, 1);
                        border: solid 5px rgba(185, 182, 180, 1);

                        padding: 25px;

                        @include md {
                            padding: 15px;
                        }

                        & > .description {
                            display: none;
                        }
                    }
                }
            }
        }
    }
</style>
