import { apiUrl, apiVersion } from "../../config";
import localforage from "localforage";

export async function authToken (tokenToBeTested) {
    const token = tokenToBeTested ?? await localforage.getItem("marier.user-token")
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/authenticate`,
        {
            "method": "GET",
            "headers": {
                "Authorization": `Bearer ${ token }`
            },
        }
    );
    if (response.ok) {
        const user = await response.json();
        delete user.status;
        return user;
    }
    if (response.status >= 400) {
        throw await response.text();
    }
    throw "Internal server error";
}

export default authToken;
