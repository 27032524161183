<template>
    <header class="nav-bar position-fixed">
        <nav class="navbar navbar-light"
             data-aos="fade-down"
             data-aos-duration="750">

            <div class="navbar-nav flex-row menu-nav"
                 data-aos="fade-in"
                 data-aos-duration="500"
                 data-aos-delay="500">
                <button class="d-flex flex-column justify-content-center align-items-center text-center navbar-button menu"
                        v-on:click.passive="openSideBar">
                    <font-awesome-icon v-bind:icon="['far', 'bars']"></font-awesome-icon>
                    <span>
                        {{ $t("message.navbar.menu") }}
                    </span>
                </button>
            </div>

            <div class="navbar-brand m-0 position-absolute text-center">
                <router-link to="/">
                    <img src="../../assets/marier_logo.svg"
                         alt="marier Skincare 曼肌兒">
                </router-link>
            </div>

            <div class="navbar-nav flex-row d-none d-md-flex"
                 data-aos="fade-in"
                 data-aos-duration="500"
                 data-aos-delay="600">
                <router-link class="d-flex flex-column justify-content-center align-items-center text-center navbar-button"
                             to="/basket">
                    <span class="badge badge-pill badge-danger cart-item-count position-absolute">
                        {{ cartLength }}
                    </span>
                    <font-awesome-icon v-bind:icon="['fal', 'shopping-cart']"></font-awesome-icon>
                    <span>
                        {{ $t("message.navbar.basket") }}
                    </span>
                </router-link>

                <router-link class="d-flex flex-column justify-content-center align-items-center text-center navbar-button"
                             v-if="userStatus !== 'loggedIn'"
                             to="/login">
                    <font-awesome-icon v-bind:icon="['fal', 'user']"></font-awesome-icon>
                    <span>
                        {{ $t("message.navbar.login-register") }}
                    </span>
                </router-link>

                <router-link class="d-flex flex-column justify-content-center align-items-center text-center navbar-button"
                             v-if="userStatus === 'loggedIn'"
                             to="/account">
                    <font-awesome-icon v-bind:icon="['fal', 'user']"></font-awesome-icon>
                    <span>
                        {{ $t("message.navbar.my-account") }}
                    </span>
                </router-link>

                <button class="d-flex flex-column justify-content-center align-items-center text-center navbar-button p-0"
                        v-if="userStatus === 'loggedIn'"
                        v-on:click="logout">
                    <font-awesome-icon v-bind:icon="['fal', 'sign-out']"></font-awesome-icon>
                    <span>
                        {{ $t("message.navbar.sign-out") }}
                    </span>
                </button>
            </div>

            <div class="navbar-nav flex-row d-flex d-md-none"
                 data-aos="fade-in"
                 data-aos-duration="500"
                 data-aos-delay="500">
                <router-link class="d-flex flex-column justify-content-center align-items-center text-center navbar-button mr-0"
                             to="/basket">
                    <span class="badge badge-pill badge-danger cart-item-count position-absolute">
                        {{ cartLength }}
                    </span>
                    <font-awesome-icon v-bind:icon="['fal', 'shopping-cart']"></font-awesome-icon>
                    <span>{{ $t("message.navbar.basket") }}</span>
                </router-link>
            </div>
        </nav>

        <notifications></notifications>
    </header>
</template>

<script>
import Notifications from "./Notifications";
export default {
    name: "NavBar",
    components: {
        Notifications
    },
    computed: {
        cartLength () {
            return this.$store.state.cart.cart.products.length + this.$store.state.cart.cart.treatments.length;
        },

        userStatus () {
            return this.$store.state.user.status;
        }
    },
    methods: {
        openSideBar () {
            this.$store.commit("layout/openSideBar");
        },

        logout () {
            this.$store.dispatch("user/logout");
        },
    }
}
</script>

<style lang="scss" scoped>
.nav-bar {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    user-select: none;

    .navbar {
        &.navbar-light {
            background-color: rgba(255, 255, 255, 0.65) !important;
            backdrop-filter: blur(5px);
        }

        & > .navbar-nav {
            z-index: 10;

            & > .navbar-button {
                position: relative;
                height: 100%;
                //width: 60px;
                margin-right: 20px;

                outline: none;
                border: none;

                background-color: transparent;
                color: rgba(113, 113, 113, 1);

                cursor: pointer;

                &:last-child {
                    margin-right: 10px;
                }

                &.menu {
                    padding: 0;
                }

                .cart-item-count {
                    right: -10px;
                    top: -7.5px;
                    border-radius: 50%;
                }

                & > svg {
                    font-size: 26px;
                    margin: 0 auto;
                }

                & > span {
                    font-size: 10px;
                }

                &:hover,
                &:focus {
                    color: rgba(45, 45, 45, 1);
                    //font-weight: bold;
                    text-decoration: none;
                }
            }
        }

        & > .navbar-brand {
            left: 0;
            right: 0;
            z-index: 1;

            img {
                height: 28px;
                width: auto;
            }
        }
    }
}
</style>
