import { apiUrl, apiVersion, } from "../../config";

function hexString(buffer) {
    const byteArray = new Uint8Array(buffer);
    const hexCodes = [...byteArray].map(
        value => {
            const hexCode = value.toString(16);
            return hexCode.padStart(2, "0");
        }
    );
    return hexCodes.join("");
}

function digestMessage(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return window.crypto.subtle.digest("SHA-512", data);
}

export async function login (
    { mode, password, email, countryCode, phone,  }
) {
    const hashed = await digestMessage(password);
    const temp = {
        "password": hexString(hashed),
        mode
    };

    switch (mode) {
        case "email": {
            temp.email = email;
            break;
        }
        case "phone": {
            temp.countryCode = countryCode;
            temp.phone = phone;
            break;
        }
        default: {
            temp.mode = "email";
            temp.email = email;
            break;
        }
    }

    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user/login`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(temp),
        }
    );

    if (response.ok) {
        const incomingToken = await response.text();
        if (incomingToken) {
            return incomingToken;
        }
        throw await response.json();
    }

    if (response.status === 400) {
        throw await response.json();
    }

    throw "Internal server error";
}

export default login;
