export const baseUrl = "https://www.marierskincare.com";
export const apiUrl = "https://api.marierskincare.com";
export const apiVersion = "v1.0";
export const apiNewVersion = "v1.1";

export const stripeKey = "pk_test_9PCO3d2WwPXelEMfBdMjeEkC";
//export const stripeKey = "pk_live_FrykYrswaLrrSgIzr5OuIJ9f";

export const PaypalKeys =  {
    sandbox: "AbothSUqtqt6TlNRDLE56WrUj0HwMe1AY7XC2SNR9RzDNFZLPMKDEug9vsvuYYiSG887dWrlYsZzWNjW",
    production: "AUQNbuUmyleFNYUcgNoic_gDbT2gyatkzgrCtvg6wcXsMbWjG6xhgAqkaAzyoQly59-XYIQLJwNKKXT7"
};

export const FacebookAppId = "1064390467326026";
export const GoogleMapKey = "AIzaSyAJlKs5zO4HEmAWBV47eBEESNd7stPZVkw";
export const GoogleClientId = "88272353276-qplnrf95i01c10uhpr3grp9236qrh0g7.apps.googleusercontent.com";

export const SupportedSNS = [
    {
        type: "email",
        icon: ["fas", "envelope"],
    },
    {
        type: "facebook",
        icon: ["fab", "facebook-square"],
    },
    {
        type: "line",
        icon: ["fab", "line"],
    },
    {
        type: "twitter",
        icon: ["fab", "twitter"],
    },
    {
        type: "telegram",
        icon: ["fab", "telegram-plane"],
    },
    {
        type: "weibo",
        icon: ["fab", "weibo"],
    },
    {
        type: "whatsapp",
        icon: ["fab", "whatsapp"],
    },
];
