<template>
    <div class="btn-back-to-top position-fixed flex-column justify-content-center align-items-center"
         v-bind:class="{ 'd-flex': showButton, 'd-none': !showButton }">
        <button class="btn btn-dark mb-1"
                v-on:click="scrollToTop">
            <font-awesome-icon icon="angle-up"></font-awesome-icon>
        </button>
        <p class="d-none d-md-block mb-0">
            {{ $t("message.back-to-top-btn.title") }}
        </p>
    </div>
</template>

<script>
    export default {
        name: "BackToTopButton",
        props: {

        },
        data () {
           return {
               showButton: false,
           }
        },
        methods: {
            scrollToTop () {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            },
        },
        mounted () {
            //this.showButton = window.scrollY > (window.innerHeight / 2);
            this.showButton = window.scrollY > 200;
            window.addEventListener("scroll", () => {
                //this.showButton = window.scrollY > (window.innerHeight / 2);
                this.showButton = window.scrollY > 200;
            });
        }
    }
</script>

<style lang="scss" scoped>
    .btn-back-to-top {
        bottom: 1rem;
        right: 1rem;
        z-index: 99;

        & > button {
            box-shadow: 0 2px 1px rgba(0, 0, 0, 0.75);
        }

        & > p {
            color: rgba(113, 113, 113, 1);
        }
    }
</style>
