<template>
    <div class="share-to-sns">
        <share-network class="btn-share mr-3"
                       v-for="{ type, icon } in networks"
                       v-bind:key="type"
                       v-bind:network="type"
                       v-bind:style="style"
                       v-bind:url="url"
                       v-bind:title="title"
                       v-bind:description="description"
                       v-bind:quote="quote"
                       v-bind:hashtags="hashtags">
            <font-awesome-icon v-bind:icon="icon"></font-awesome-icon>
        </share-network>
    </div>
</template>

<script>
export default {
    name: "ShareToSNS",
    props: {
        "networks": {
            type: Array,
            default: () => [{
                type: "email",
                icon: ["fas", "envelope"],
            }],
        },
        "style": {
            type: Object,
        },
        "url": {
            type: String,
            required: true,
        },
        "title": {
            type: String
        },
        "description": {
            type: String
        },
        "quote": {
            type: String
        },
        "hashtags": {
            type: String
        },
    },
    data() {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.share-to-sns {
    .btn-share {
        color: rgba(113, 113, 113, 1);
        font-size: 28px;
        margin-bottom: 7.5px;
    }
}
</style>
