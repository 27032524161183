<template>
    <div class="btn btn-block btn-outline-dark d-flex flex-column align-items-stretch font-weight-bold h-100 overflow-hidden p-0 branch-option"
         v-bind:class="{ 'active': selected }"
         v-on:click="select">
        <gmap-map class="branch-option-map rounded"
                  v-bind:center="branch.coordinate"
                  v-bind:zoom="defaultMapZoom"
                  v-bind:options="{ styles: mapStyle }">
            <gmap-marker v-bind:position="branch.coordinate"
                         v-bind:clickable="false"
                         v-bind:draggable="false">
            </gmap-marker>
        </gmap-map>
        <div class="branch-info p-4">
            <h4 class="mb-2"
                v-if="branch.name">
                {{ branch.name[$i18n.locale] }}
            </h4>
            <h4 class="mb-2"
                v-else>
                {{ locationTypeMap[branch.type][$i18n.locale] }}
            </h4>
            <p class="mb-1 font-weight-normal">
                {{ branch.address[$i18n.locale] }}
            </p>
            <p class="mb-1 font-weight-normal">
                {{ $t("message.treatments.openHours") }}{{ branch.openHours.from | dateFnsFormatter("HH:mm") }} - {{ branch.openHours.to | dateFnsFormatter("HH:mm") }}
            </p>
            <p class="mb-0 font-weight-normal">
                {{ $t("message.treatments.hotline") }}{{ branch.phone }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TreatmentBranchOption",
        props: {
            "branch": {
                type: Object,
                required: true,
            },

            "selected": {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                defaultMapZoom: 15,
                mapStyle: [
                    {
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#f5f5f5"
                        }]
                    }, {
                        "elementType": "labels.icon",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#616161"
                        }]
                    }, {
                        "elementType": "labels.text.stroke",
                        "stylers": [{
                            "color": "#f5f5f5"
                        }]
                    }, {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#bdbdbd"
                        }]
                    }, {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#eeeeee"
                        }]
                    }, {
                        "featureType": "poi",
                        "elementType": "labels.text",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#757575"
                        }]
                    }, {
                        "featureType": "poi.business",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#e5e5e5"
                        }]
                    }, {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#9e9e9e"
                        }]
                    }, {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#ffffff"
                        }]
                    }, {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#757575"
                        }]
                    }, {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#dadada"
                        }]
                    }, {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#616161"
                        }]
                    }, {
                        "featureType": "road.local",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#9e9e9e"
                        }]
                    }, {
                        "featureType": "transit",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#e5e5e5"
                        }]
                    }, {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#eeeeee"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#c9c9c9"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#9e9e9e"
                        }]
                    }],

                locationTypeMap: {
                    "salon": {
                        "zh-hk": "marier美容中心",
                        "zh-cn": "marier美容中心",
                        "en": "marier Salon"
                    },

                    "counter": {
                        "zh-hk": "marier專門店/專櫃",
                        "zh-cn": "marier专门店/专柜",
                        "en": "Marier Counter"
                    }
                },
            };
        },
        computed: {

        },
        methods: {
            select () {
                this.$emit("click", this.branch);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .branch-option {
        cursor: pointer;
        white-space: normal;

        & > .branch-option-map {
            width: 100%;
            height: 180px;
            border-radius: 4px;
        }

        & > .branch-info {

        }
    }
</style>
